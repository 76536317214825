.guide{
    // @include sp{
    // &:not(#privacy-policy){
    //     .lowerhero{
    //         margin-bottom: -50px;
    //     }
    // }     
       
    // }

    .sectionBtn{
        background-color: $guide;
        box-shadow: 0px 0px 6px 3px #ffe5cc;
    }
    .site-main{
        .link{
            color: $guide;
            // border-bottom: 1px solid $guide;
        }
    }
    
    .point{
        background-color:#fdf1e4;
        .lists{
            .colored{
                color: #d56a01;
                &::before{
                    background-color: #d56a01;
                }
            }
        }
    }
    .sectionHeading{
        color: $guide;
        &::before{
            background-image: url(../../img/top_backimage04.png);
        }
        @include sp{
            &::before{
                background-image: url(../../img/top_sp_backimage04.png);
            }
        }
    }
    .colored{
        color: $guide;
        display: block;
        // &::before{
        //     background-color: #ff8e1e;
        // }
    }
    // li{
    //     &.colored{
    //         &::before{
    //             background-color: $guide;
    //         }
    //     }
        
    // }
    .page-heading{
        box-shadow: 0px 0px 6px 3px #ffe5cc;
        h1{
            color: $guide;
        }
    }
    .point2{
        border: 1px solid #ffe5cc;
        box-shadow: 4px 4px 6px 0px #ffe5cc;
        // background-color: #fff;
        margin: 10px 0;
        .pointHeading{
            color: $guide;

        }
    }
    .captitle{
        color: $guide;
    }
    h3{
        &::after{
        background-color: rgba(#ffe5cc,0.3);
        }
    }
    .contentHeading{
        &::before{
            background-color: $guide;
        }
        &.fade_on{
            &::before{
                animation-name:orangepoint-a;
            }
            
        }
    }

    .caution{
        color: $guide;
        font-weight: bold;
    }

    .history{
        table{
            th{
                text-align: center;
                background-color: rgba(#ffe5cc,0.3);
                font-weight: bold;
                color:$guide;
                padding: 1em;
            }
            td{
                display: flex;
                justify-content: space-between;
                border: none;
                @include sp{
                    display: block;
                }
            }
        }
    }



    &#guide{
        .rinen_heading{
            font-size: 36.8px;
            @include sp{
                font-size: 1.7em;
            }
            span{
                display: block;
                font-size: 27.2px;
                @include sp{
                    font-size: 20px;
                }
            }
        }
        .section_content{
            &.rinen{
                // @include min1080{
                //     display: flex;
                //     overflow: visible !important;
                //     flex-direction: row-reverse;
                // }
                .content_left{
                    @include min1080{
                        // width: 59%;
                        width: 58%;
                        
                    }

                    .point{
                        padding: 15px;
                        margin: 0;
                        .lists{
                            padding-left: 2%;
                            @include sp{
                                padding-left: 4%;
                            }
                        }
                    }
                }
                // .content_right{
                //     width: 100%;
                //     margin: 0 auto;
                //     text-align: center;
                //     @include min1080{
                //         width: 41%;
                //         // margin-top: -70px;
                //         // padding: 20px 0 0 40px;
                //         // width: 40%;
                //         padding: 13px 0 0 30px;
                //     }
                //     img{
                //         width: 100%;
                //     }
                //     .profile{
                //         display: inline-block;
                //         text-align: center;
                //     }
                // }
            }

            &.rijicho{
                @include min1080{
                    .content_right{
                        width: 30%;
                    }
                    .content_left{
                        width: 70%;
                        // padding-left: 30px;
                    }
                }
                         
             }



        }
        .point2{
            background: url(../../img/jimucho_message.jpg) no-repeat right bottom;
            background-size: 360px;
            padding-bottom: 80px;
            @include sp{
                background-size: 260px;
            }

            p{
                text-shadow:1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8)
            }
        }

        .wp-video{
            @include sp{
                margin: 0 auto;
            }
        }


        
    }
    
    &#regional-activity{
        h3{
            margin-top: 0;
        }
    }
    
    
    
    &#recruit{
        // .sectionHeading{
        //     margin-bottom: 50px;
        //     @include sp{
        //         margin-bottom: 30px;
        //     }
        // }
        .contentHeading{
            margin-bottom: 15px;
            font-weight: bold;
        }
        .recruit_table{
            border: none;
            margin-bottom: 70px;
            td,th{
                padding: 8px 15px;
                border: none;
                @include max400{
                    padding: 8px 6px;
                }
            }
            th{
                color:$guide;
                // border-bottom:2px solid rgb(192, 171, 151)!important;
                border-bottom:2px solid $guide;
                @include sp{
                    width: 27%;
                }
            }
            td{
                border-bottom: 2px solid #ccc;
                > ul{
                    overflow: hidden;
                    padding-top: 5px;
                    > li{
                        float: left;
                        >{
                            ul{
                                float: right;
                            }
                        }
                    }
                }
            }
            tr{
                border: none;
            }
        }

        .section_content{
            .content_right{
                img{
                    width: 100%;
                }
            }
        }

        .voices{
            background-color: #fdf1e4;
            border-radius: 10px;
            margin-bottom: 40px;
            width: 100%;
            position: relative;
            padding: 20px;
            @include sp{
                padding: 20px 20px 130px;
            }
            figure{
                width: 100%;
                // @include sp{
                //     width: 30%;
                //     display: inline-block;
                    
                // }
            }
            .listcontent_left{
                width: 80%;
                // float: left;
                @include sp{
                    width: 100%;
                }
            }
            .listcontent_right{
                width: 90px;
                margin-top: -10px;
                position: absolute;
                bottom: 0;
                right: 30px;
                // float: right;
                @include min1080{
                    width: 9%;
                }
                // @include sp{
                //     // width: 15%;
                //     // position: absolute;
                //     // bottom: 0px;
                //     // right: 0;
                // }
            }

        }

        .voice{
            list-style-type: none;
            li{
                padding-left: 22px;
                position: relative;
                margin-bottom: 1em;
                // line-height: 2;
                &::before{
                    content:" ";
                    width: 15px;
                    height: 15px;
                    margin-left: -24px;
                    margin-right: 10px;
                    border-radius: 50%;
                    display: inline-block;
                    position: relative;
                    top: 2px;
                    background-color: #333;
                    @include sp{
                        top: 0;
                        width: 10px;
                        height: 10px;
                    }
                }
                p{
                    margin-bottom: 0;
                }
            }
        }
    
        .point{
            margin-bottom: 30px;
            // @include pc{
                padding: 20px 20px 0;
            // }
            p{
                margin-bottom: 5px;
                padding-left: 25px;
                @include sp{
                    padding-left: 15px;
                }
                
            }
        }
    
        .point2{
            padding: 20px 14px;
            text-align: center;
            @include sp{
                padding: 20px 14px 28px;
            }
            
            .colored{
                font-size: 1.6em;
                font-family: $mincho;
                
                font-weight: bold;
            }
    
            .contactBox{
                margin-top: 30px;
                .telHeading{
                    position: relative;
                    display: inline-block;
                    color: $guide;
                    font-size: 1.8em;
                    font-weight: bold;
                    vertical-align: middle;
                    margin: 0;
                    @include sp{
                        margin: 0 0 10px 0;
                    }
                    &::before{
                        font-family: "Font Awesome 5 Free";
                        content: "\F879";
                        font-size: 0.8em;
                        margin-right: 10px;
                        margin-top:6px;
                        
                    }
                }
                .contactBtn{
                    padding: 5px 20px;
                    position: relative;
                    display: inline-block;
                    background-color: $guide;
                    color: #ffffff;
                    border-radius: 50px;
                    @include pc{
                        margin-left: 20px;
                    }
                    @include sp{
                        width: 100%;
                    }
                    &::after{
                        font-family: "Font Awesome 5 Free";
                        content:"\f0e0";
                        // font-size: 0.8em;
                        @include pc{
                            margin-right: 10px;
                        }
                        @include sp{
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    
        .subsectionHeading{
             font-size: 1em; 
        // display: inline-block;
            margin: 0 0 30px;
        }
    }
    
    &#privacy-policy{
        .page-heading{
            margin-bottom: 0;
        }
        .mb{
            margin-bottom: 20px;
        }
    }

    &#guide,&#regional-activity{
    
        // .section_content{
        //     @include pc{
        //         flex-direction: row-reverse;
        //     }
        //     @include ffonly{
        //         flex-direction: row-reverse;
        //     }
            
        // }

        // .profile{
        //     @include sp{
        //         text-align: center;
        //     }
        // }
    
        .point{
            .pointHeading{
                margin-bottom: 0;
            }
            .ruby{
                font-size: 0.8em;
                @include sp{
                    font-size: 0.7em;
                }
            }
            .lists{
                li{
                    line-height: inherit;
                }
            }
            @include sp{
                margin-bottom: 100px;
            }
            
        }
        .point2{
            position: relative;
            z-index: 11;
            background-color: #fff;
        }
        .flexlist{
            // justify-content: left;
            flex-wrap: wrap;
            
            @include sp{
                display: flex;
                justify-content: normal;
            }
            img{
                display: block;
                width: 18%;
                margin: 10px;
                @include sp{
                    width: 28%;
                }
                @include max400{
                    width: 42%;
                }
            }
        }
        .right{
            @include pc{
                width: 33%;
                padding-left: 30px;
            }
            // .profile{
            //     @include sp{
            //         figure{
            //             width: 100%;
            //             img{
            //                 margin: 0 auto;
            //                 width:50%;
            //             }
            //             .wp-caption-text{
            //                 text-align: center;
            //             }
            //         }
            //     }
                
            // }
        }
        .left{
            @include pc {
                width: 65%;
            }
        }
        .pointFlex{
            @include pc{
                flex-direction: row-reverse;
            }
            @include sp{
                display: block;
                
            }
            .right{
                @include sp{
                    width: 100%;
                    padding: 0;
                    margin: 10px 0;
                }
                
            }
            .left{
                @include sp{
                width: 100%;
                }
            }
        }
    }
}