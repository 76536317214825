.hospitalization{
    .sectionBtn{
        background-color: $hospital!important;
        box-shadow:0px 4px 9px 0px rgba($hospital,0.4)!important;

    }
    .point{
        background-color: #fec8d6;
    }
    .sectionHeading{
        color: $hospital;
        &::before{
            background-image: url(../../img/top_backimage03.png);
        }
        @include sp{
            &::before{
                background-image: url(../../img/top_sp_backimage03.png);
            }
        }
    }
    .colored{
        color: $hospital;
        display: block;
    }
    .page-heading{
        box-shadow: 0px 0px 6px 3px #fec8d6;
        h1{
            color: $hospital;
        }
    }
    .point2{
        border: 1px solid #fec8d6;
        box-shadow: 4px 4px 6px 0px #fec8d6;
        margin-bottom: 0;
        .pointHeading{
            color: $hospital;
        }
    }
    .caution{
        color: $hospital;
        font-weight: bold;
    }
    .captitle{
        color: $hospital;
    }
    h3{
        &::after{
        background-color: rgba(#fec8d6,0.3);
        }
    }
    .contentHeading{
        &::before{
            background-color: $hospital;
        }
        &.fade_on{
            &::before{
                animation-name: pinkpoint-a;
            }
            
        }
    }
    .site-main{
        .link{
            color: $hospital;
            // border-bottom: 1px solid $hospital;
        }
    }

    &#hospitalization{
        .page-heading{
            margin-bottom: 0;
          }
          
        .headingImg{
            // margin-top: 50px;
            margin-bottom: 50px;
        }

        .flex{
            @include pc{
                width: 100%;
                justify-content: left;
            }
            @include sp{
                display: flex;
                justify-content: inherit;
                flex-wrap: wrap;
            }
            @include max400{
                display: block;
            }
            > img {
                width: 23%;
                margin: 8px;
                // padding: 1%;
                @include max400{
                    width: 43%;
                    margin-bottom: 3%;
                    display: inline-block;
                    
                    // width: 50%;
                    // padding:3px;
                }
            }
        }
        
        #section01{
            margin-bottom: 70px;
        }
        #section02{
            margin-bottom: 100px;
            
        }
        .point2{
            .flex{
                // justify-content: left;
                margin: 15px 10px;
                @include sp{
                    display: block;
                }
            }
            .lists{
                @include min1080{
                    width: 30%;
                }
                
                margin-right: 5%;
            }
        }
        .lists{
            padding-left: 1%;
            @include sp{
            padding-left: 3%;    
            }
            li{
                &::before{
                    left: -5px;
                }
            }
            
        }
    }

    &#equipment{
        #section01,#section02{
            @include sp{
                margin-bottom: 100px;
            }
        }
        .content_left{
            @include sp{
                margin-bottom: 40px;
            }
        }
        
    }
    
}