.site-header {
    z-index: 11111;
    // @include sp{
    //     max-height: 100px;
    //     height: 100%;
    // }
}
.header-subtitle{
    display: block;
    font-size: 0.9em;
    @include tablet{
        width: 50%;
    }
    
}
.header-flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1240px;
    padding: 0 20px;

    @include sp{
        // position: absolute;
        padding: 22px 12px 10px;
    }
    @include max400{
        padding: 12px 12px 10px;
    }
    &.above{
        @include sp{
            display: none;
        }
    }
    &.bottom{
        align-items: baseline;
    }
}


// .menu-top-container,
.menu-sp-container{
    // @include sp{
        position: absolute !important;
        width: 70%;
        right: 0;
        top:100%;
    // }
}

// #top-menu{
//     @include sp{
//         padding: 5% !important;
//     }
// }

.main-navigation{
    li{
        &.parentCatLink{
            @include pc{
                // margin-bottom: 7px;
                
                display: inline-block;
                // height: 57px;
                &:last-child{
                    // padding-top: 10px;
                    a{
                    border-right: 1px solid #ccc;
                    // border-right: 0;
                        // padding: 10px 0;
                    }
                }
            }
            @include sp{
                &::after,&::before{
                    content: '';
                    display: block;
                    width: 15px;
                    height: 3px;
                    border-radius: 5px;
                    background: #474747;
                    position: absolute;
                    right: 20px;
                    top: 30px;
                    transform: translateY(-50%);
                }
                &::after {
                    // background: red;
                    /* 横棒を縦にするために90℃回転させる */
                    transform: translateY(-50%) rotate(90deg);
                    /* プラスからマイナスへの切り替えをゆっくり行いアニメーションさせる */
                    transition: 0.5s;
                    // &.focus{
                    //     transform: rotate(0);
                    //     transition: 0.5s;
                    // }
                  }
                &.focus{
                    &::after {
                        transform: rotate(0);
                        transition: 0.5s;
                        top: 28.9px;
                      }
                }

                
            }
            >a{
                display: block;
                // margin-bottom: 7px;
                // height: 100%;
                cursor:default;
                &:hover{
                    opacity: 1;
                }
                @include pc{
                border-left: 1px solid #ccc;

                }
                @include tablet{
                    height: 93%;
                }
            }
            &.oshirase{
                >a {
                    cursor:pointer;
                &:hover{
                    opacity: 0.7;
                }
                }
                
            }
            

        }

        > a{
            @include sp{
                padding: 6px;
            }
        }
    }
}

// .menu-top-container,
// .menu-sp-container{
//     @include sp{
//         max-height: 590px;
//     overflow: auto;
//     -webkit-overflow-scrolling: touch;
//     }
//     @include max400{
//         max-height: 590px;

//     }
    
// }

.header-flex .header-left{
    // width: 30%;
    width: 23%;
    // padding-top: 38px;
    z-index: 11111111;
    @include sp{
        width: 60%;
        // padding-top: 10px;
    }

    @include max400{
        width: 58%;
        padding-top: 0;
    }
    
    .logo{
        width: 85%;
        margin: 0;
        padding-bottom: 10px;

        @include sp{
            padding-bottom: 0;

        }
    }
    .tel{
        width: 100%;
        font-size: 2em;
        @include sp{
            font-size: 2.3em;
        }
        @include max400{
            font-size: 1.2em;
        }
    }
}

.header-flex .header-right{
    
    @include pc{
        width: 55%;
    }
    @include tablet{
    width: 70% !important;
    }
}

.header-flex .right-above{
    display:flex;
    align-items: center;
    justify-content: flex-end;
    // margin-bottom: 10px;
    @include sp{
        display: none;
    }
    .header-subtitle{
        @include tablet{
            display: block;
             width: 44%;
        }
        
    }
    
}

.header-flex .tel{
    font-size: 2em;
    font-weight: bold;
    color: #2f7445;
    position: relative;
    margin-bottom: 0;
    margin-right: 20px;
    margin-right: 32px;
    @include tablet{
        font-size: 1.8em;
    }
    &::before{
        font-family: "Font Awesome 5 Free";
        content: "\f879";
      //   color:$green;
        margin-right: 10px;
        @include sp{
            font-size: 0.8em;
            // padding-left: 10px;
        }
        @include max400{
            font-size: 20px;
            // padding-left: 10px;
        }
    }
}


.right-above .access{
    display: inline-block;
    /* height: 40px; */
    border-radius: 30px;
    background-color: #2f7445;
    color: #ffffff;
    position: relative;
    padding: 10px 30px;
    // font-size: 1.8rem;
    line-height: 1;
    &::after{
        font-family: "Font Awesome 5 Free";
        content: "\f3c5";
        padding-left: 0.5em;
        color: #ffffff;
    }
}

.navigation-top {
    top: 0;
    font-size: 14px;
    font-size: 0.875rem;
    left: 0;
    position: fixed;
    // height: 230px;
    // position: sticky !important;
    right: 0;
    width: 100%;
    z-index: 7;
    // height: 100%;
    // height: 15%;
    background-color: #fff;
    padding-top:4px;
    @include media(1100px){
        height: auto;
    }
    @include sp{
        
        position: fixed !important;
        padding-top: 0 !important;
    }
}

@include tablet{
    .sub-menu {
        padding: 0 5% 0 !important;
    }
}

// @include sp{
//     .sub-menu{
//         display: none ;
//     }
// }

.main-navigation ul ul {
    padding: 0;
}

@include ieonly{
    .home #test-site-navigation.main-navigation > div > ul {
        // width: 100%;
        @include min1080{
            display: inline-block;
        }
		
    }
    // .js .main-navigation.toggled-on > div > ul {
    //     display: inline-block;
    // }
}

@include media(2100px){
    .parentCatLink{
        > .sub-menu{
            justify-content: center !important;
        }
        &:first-child{
             .sub-menu{
                .ic{
                    width: 20% !important;
                }
            }
        }
        &:nth-of-type(2){
             .sub-menu{
                .ic{
                    width: 12%;
                }
            }
        }
    }
}
