.menu-social-container{
    #social-menu{
        @include sp{
            display: block;
        }
        > .menu-item{
            @include sp{
                width: 100%;
                margin-bottom: 40px;
            }
            &:first-child{
                > a{
                    color:$guide;
                    &::before{
                        background-color:#ffe5cc;
                    }
                }
                .sub-menu{
                    li{
                        a{
                            &:hover{
                            color:$guide;
                            }
                        }
                    }
                }
            }
            
            &:nth-child(2){
                > a{
                    color:$outpatient;
                    &::before{
                        background-color:#bdcdfe;
                    }
                }
                .sub-menu{
                    li{
                        a{
                            &:hover{
                            color:$outpatient;
                            }
                        }
                    }
                }
            }
            &:nth-child(3){
                > a{
                    color:$hospital;
                    &::before{
                        background-color:#fec8d6;
                    }
                }
                .sub-menu{
                    li{
                        a{
                            &:hover{
                            color:$hospital;
                            }
                        }
                    }
                }
            }
            
            
             a{
                font-size: 1.4em;
                position: relative;
                z-index: 1;
                color: #222;
                @include sp{
                    margin-right: 2em;
                }
                @include max400{
                    margin-right: 0;
                }
                &::before{
                    content:" ";
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    position: absolute;
                    top:0;
                    left: 0;
                    z-index: -1;
                }
                
            }
            .sub-menu{
                // display: block !important;
                li{
                    a{
                        &:hover{
                            opacity: 1;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .shinsatsu-lists{
            ul{
                @include sp{
                    display: flex;
                    flex-wrap: wrap;
                }
                @include ieonly{
                    display: block;
                }
                @include max400{
                    display: block;
                }
            }
            
        }
        .list-heading{
            @include pc{
                margin-right: 5%;
            }
            @include tablet{
                margin-right: 2%;
                width: 32%;
            }
            > a{
                display: block;
                // margin-bottom: 1em;
                padding: 10px 10px 10px 14px;
                text-shadow:0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff;
                cursor: default;
                &:hover{
                    opacity: 1;
                }
            }
            
            
        }
        .sub-menu{
            li{
                line-height: 2;
            }
        }
    }
}


#social-menu li ul li a{
    font-size: 1em !important;
}