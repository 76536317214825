.section02{
    width: 100%;
    max-width: 1200px;
    margin-bottom: 80px;
    padding:0 20px 0 100px;
    @include tablet{
        padding:0 20px 0 73px;
    }
    @include sp{
        
        padding: 0 20px;
    }
    h2{
        text-align: left;
        font-size: 1.85em;
        @include pc{
            margin-bottom: 60px;

        }
        @include sp{
            margin-bottom: 20px;
        }
        @include max400{
            font-size: 1.45em;
            margin-bottom: 10px;
        }

    }
    .imgBox{
        width: 100%;
        @include sp{
            margin-bottom: 7%;
        }

    }
    &_content{
        display: flex;
        justify-content: space-between;
        
        .txt{
            width: 48%;
            text-align: left;
            @include sp{
                width: 100%;
            }
            p{
                line-height: 1.6;
            }
        }
        .imgBox{
            width: 56%;
            position: relative;
            margin-top: -30px;
        }
        .moreInfo{
            display: inline-block;
            // padding: 10px 20px 5px 10px;
            padding: 10px 26px 5px 10px;
            transition: 0.3s;
            transition-timing-function: ease;
            position: relative;
            left: 0;
            font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "\6E38\660E\671D\4F53", sans-serif;
            color: $green;
            font-weight: bold;
            // font-size: 0.9em;
            border-bottom: 1px solid $green;
            &:hover{
            // padding: 10px 20px;
            // left: 20px;
                opacity: 1;
                background-color: $green;
                color: #ffffff;
                // border-bottom: none;

            }
        }

    
    }
}