.section05{
    
    display: flex;
    max-width: 1200px;
    padding: 0 72px;
    width: 100%;
    margin:0 auto;
    margin-bottom: 70px;
    // justify-content:center;
    @include sp{
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 10px 20px;
    }
    @include tablet{
        display: block;
    }
    .newsHeading{
        width: 19.7%;
        @include sp{
            width: 100%;
        }
        @include tablet{
            width: 60%;
            display: flex;
            align-items: center;
        }
        .newsTitle{
            z-index: 1;
            // color: $green;
            @include sp{
                text-align: center;
            margin-bottom: 80px;
            margin-top: 50px;
            width: 100%;
            position:relative;
            // @include sp{
            //     text-align: center;
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            //     margin-bottom: 100px;
            &::before{
                content: " ";
                width: 90%;
                height: 140px;
                display: block;
                background-image: url(../../img/top_sp_backimage01.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center top;
                position: absolute;
                top: -50px;
                left: 0;
                right: 0;
                margin: auto;

            //     content: " ";
            //     width: 90%;
            //     height: 140px;
            //     display: block;
            //     background-image: url(../../img/top_sp_backimage01.png);
            //     background-size: contain;
            //     background-repeat: no-repeat;
            //     background-position: center top;
            //     position: absolute;
            //     left: 0;
            //     right: 0;
            //     margin: auto;
            //     @include ieonly{
            //         top:-46px;
            //     }
            // }
            }
            }
            
            @include max400{
                margin-bottom: 60px;
            }
        }
        
    }
    .newsBtn{
        display: inline-block;
        // padding: 10px 20px 5px 10px;
        padding: 10px 26px 5px 10px;
        transition: 0.3s;
        transition-timing-function: ease;
        position: relative;
        // left: 0;
        font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "\6E38\660E\671D\4F53", sans-serif;
        color: $green;
        font-weight: bold;
        border-bottom: 1px solid $green;
        &:hover{
           
            @include min1080{
                
                // left: 20px;
                opacity: 1;
                background-color: $green;
                color: #ffffff;
                border-bottom: none;
            }
        }
        &.arrow{
            transition: 0.3s;
            &:hover{
                &::after{
                    @include sp{
                        border-color: transparent transparent transparent $green2;
                    }
                    
                }
                
            }
            &::after{
                @include tablet{
                    top:20% !important;
                }
                
            }
        }
        @include tablet{
            padding: 0;
            border-bottom: none;
            margin-left: 30px;
        }
    }
    .news{
        width: 80%;
        @include sp{
            width: 100%;
        }
    }
    .tabBox{
        display:flex;
        // width: 60%;
        width: 41%;
        @include tablet{
            width: 60%;
        }
        @include sp{
            width: 90%;
        }
    }
    .tab{
        display: inline-block;
        flex-grow: 1;
        padding:5px;
        list-style:none;
        font-size: 0.8em;
        // border:solid 1px $green;
        text-align:center;
        cursor:pointer;
        
        
    }
    

    .tab1{
        background-color: $green2;
        color: #ffffff;
        // &.active{
        //     background:$green;
        //     color:#FFF;
        //     transition: all 0.2s ease-out;
        // }
    }

    .tab2{
        background-color: #ccc;
        .day{
            color: #666666 !important;
        }
        // &.active{
        //     background:#222;
        //     color:#FFF;
        //     transition: all 0.2s ease-out;
        // }
    }
    // .infolists{
    //     padding: 20px;
    //     border:solid 2px $green;
    //     background:#fff;
        
    // }
    .panel{ /*非表示にさせます*/
        display:none;
        padding: 20px 20px 10px 20px;
        
        background: #fff;

        &.show{
            display:block;
            &.tab1{
                border: solid 2px $green2;
            }
            &.tab2{
                border: solid 2px #ccc;
            }
        }
        
    }
    .infolist_content{
            // display: flex;
            // align-items: center;
            border-bottom: 1px dotted #222;
            line-height: 1.4;
            margin-bottom: 10px;
            @include sp{
                display: block;
            }
            
            .day{
                width: 23%;
                color: $green;
                font-weight: bold;
                display: inline-block;
                @include sp{
                    width: 100%;
                }
            }
            dd{
                width: 70%;
                display: inline-block;
                @include sp{
                    width: 100%;
                }
            }
            .infolist_txt{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: $blk;
                transition: 0.2s;
                display: inline-block;
                width: 100%;
                font-size: 0.95em;
                vertical-align: middle;
                    &:hover{
                    opacity: 1;
                    color: $green;
                    text-decoration: underline;
                }
                @include sp{
                    width: 100%;
                }
            }
        }
    .linkWrap{
        width: 100%;
        text-align: right;
        @include sp{
            margin:10px 0 50px;
        }
    }
    .none_article{
        color: #4a4a4a;
    }
}