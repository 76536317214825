@charset "UTF-8";
/* =========================================================

 変数のみaaa

========================================================= */
@media screen and (min-width: 769px) {
  .sponly {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .pconly {
    display: none !important;
  }
}

.excepttablet {
  display: none;
}
@media only screen and (min-width: 1080px) and (max-width: 768px) {
  .excepttablet {
    display: block !important;
  }
}

@media only screen and (min-width: 1080px) and (max-width: 768px) {
  .showtabletonly {
    display: none !important;
  }
}

@media screen and (max-width: 1110px) {
  .showpconly {
    display: none;
  }
}

@media screen and (min-width: 1110px) {
  .exceptpc {
    display: none;
  }
}

.mincho {
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  font-weight: bold;
}

.opacity0 {
  opacity: 0;
  transform: translateY(80px);
  transition: 1s;
}

/****************tttttttttttteeeeeeeeeeeeeeeesssssssssssssttttttttttttt******************/
html {
  font-size: 16px;
  box-sizing: border-box;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
}

body {
  width: 100%;
  word-break: break-word;
  font-weight: 400;
  line-height: 1.85;
  color: #4a4a4a;
  margin: 0;
  padding: 0;
  word-break: break-all;
  font-family: "游ゴシック Medium", YuGothic, "Yu Gothic", sans-serif, "ヒラギノ角ゴシック", "Hiragino Sans";
  font-display: swap;
  background: #fff;
  /*IE用にマテリアルアイコン映す*/
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  font-feature-settings: "kern", "liga", "clig", "calt";
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

code,
pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: "游ゴシック Medium", YuGothic, "Yu Gothic", sans-serif, "ヒラギノ角ゴシック", "Hiragino Sans";
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

object {
  pointer-events: none;
}

img,
audio,
video {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  border: none;
}

iframe {
  border: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #e2e2e2;
}

td,
th {
  padding: 0.5rem;
  text-align: left;
  border: 1px solid #e2e2e2;
  word-break: break-all;
}

table th {
  color: #363636;
}

h1, h2 {
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  color: #015C21;
  font-weight: normal;
}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 1.7em;
}

h3 {
  font-size: 1.1rem;
  margin: 1.1rem 0;
}

p {
  margin: 0 0 1rem;
}

ul {
  list-style: none;
}

ol {
  margin-left: 1em;
}

a {
  color: #3273dc;
  text-decoration: none;
}
a:hover {
  cursor: pointer;
}
@media screen and (min-width: 769px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

.notlink > a {
  pointer-events: none;
}

b,
strong {
  color: #363636;
  font-weight: bold;
}

small {
  font-size: 0.875em;
}

hr {
  border: none;
  display: block;
}

pre {
  -webkit-overflow-scrolling: touch;
  background-color: whitesmoke;
  color: #4a4a4a;
  font-size: 0.875em;
  overflow-x: auto;
  padding: 1.25rem 1.5rem;
  white-space: pre;
  word-wrap: normal;
}
pre code {
  background-color: transparent;
  color: currentColor;
  font-size: 1em;
  padding: 0;
}

input[type=checkbox],
input[type=radio] {
  vertical-align: baseline;
}

input[type=tel],
input[type=email],
input[type=text] {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 1rem;
  appearance: none;
  border-bottom: 2px solid #4a4a4a;
}
input[type=tel]:focus,
input[type=email]:focus,
input[type=text]:focus {
  border-bottom: 2px solid #3273dc;
}

textarea {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 1rem;
  appearance: none;
  border: 2px solid #4a4a4a;
}
textarea:focus {
  border: 2px solid #3273dc;
}

button,
[type=button],
[type=reset],
[type=submit],
[role=button] {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  appearance: none;
}
button:hover,
[type=button]:hover,
[type=reset]:hover,
[type=submit]:hover,
[role=button]:hover {
  cursor: pointer;
}

/*******************************
kokomade************/
.site-header {
  z-index: 11111;
}

.header-subtitle {
  display: block;
  font-size: 0.9em;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .header-subtitle {
    width: 50%;
  }
}

.header-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px;
}
@media only screen and (max-width: 768px) {
  .header-flex {
    padding: 22px 12px 10px;
  }
}
@media only screen and (max-device-width: 560px) {
  .header-flex {
    padding: 12px 12px 10px;
  }
}
@media only screen and (max-width: 768px) {
  .header-flex.above {
    display: none;
  }
}
.header-flex.bottom {
  align-items: baseline;
}

.menu-sp-container {
  position: absolute !important;
  width: 70%;
  right: 0;
  top: 100%;
}

@media screen and (min-width: 769px) {
  .main-navigation li.parentCatLink {
    display: inline-block;
  }
  .main-navigation li.parentCatLink:last-child a {
    border-right: 1px solid #ccc;
  }
}
@media only screen and (max-width: 768px) {
  .main-navigation li.parentCatLink::after, .main-navigation li.parentCatLink::before {
    content: "";
    display: block;
    width: 15px;
    height: 3px;
    border-radius: 5px;
    background: #474747;
    position: absolute;
    right: 20px;
    top: 30px;
    transform: translateY(-50%);
  }
  .main-navigation li.parentCatLink::after {
    /* 横棒を縦にするために90℃回転させる */
    transform: translateY(-50%) rotate(90deg);
    /* プラスからマイナスへの切り替えをゆっくり行いアニメーションさせる */
    transition: 0.5s;
  }
  .main-navigation li.parentCatLink.focus::after {
    transform: rotate(0);
    transition: 0.5s;
    top: 28.9px;
  }
}
.main-navigation li.parentCatLink > a {
  display: block;
  cursor: default;
}
.main-navigation li.parentCatLink > a:hover {
  opacity: 1;
}
@media screen and (min-width: 769px) {
  .main-navigation li.parentCatLink > a {
    border-left: 1px solid #ccc;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .main-navigation li.parentCatLink > a {
    height: 93%;
  }
}
.main-navigation li.parentCatLink.oshirase > a {
  cursor: pointer;
}
.main-navigation li.parentCatLink.oshirase > a:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 768px) {
  .main-navigation li > a {
    padding: 6px;
  }
}

.header-flex .header-left {
  width: 23%;
  z-index: 11111111;
}
@media only screen and (max-width: 768px) {
  .header-flex .header-left {
    width: 60%;
  }
}
@media only screen and (max-device-width: 560px) {
  .header-flex .header-left {
    width: 58%;
    padding-top: 0;
  }
}
.header-flex .header-left .logo {
  width: 85%;
  margin: 0;
  padding-bottom: 10px;
}
@media only screen and (max-width: 768px) {
  .header-flex .header-left .logo {
    padding-bottom: 0;
  }
}
.header-flex .header-left .tel {
  width: 100%;
  font-size: 2em;
}
@media only screen and (max-width: 768px) {
  .header-flex .header-left .tel {
    font-size: 2.3em;
  }
}
@media only screen and (max-device-width: 560px) {
  .header-flex .header-left .tel {
    font-size: 1.2em;
  }
}

@media screen and (min-width: 769px) {
  .header-flex .header-right {
    width: 55%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .header-flex .header-right {
    width: 70% !important;
  }
}

.header-flex .right-above {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 768px) {
  .header-flex .right-above {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .header-flex .right-above .header-subtitle {
    display: block;
    width: 44%;
  }
}

.header-flex .tel {
  font-size: 2em;
  font-weight: bold;
  color: #2f7445;
  position: relative;
  margin-bottom: 0;
  margin-right: 20px;
  margin-right: 32px;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .header-flex .tel {
    font-size: 1.8em;
  }
}
.header-flex .tel::before {
  font-family: "Font Awesome 5 Free";
  content: "";
  margin-right: 10px;
}
@media only screen and (max-width: 768px) {
  .header-flex .tel::before {
    font-size: 0.8em;
  }
}
@media only screen and (max-device-width: 560px) {
  .header-flex .tel::before {
    font-size: 20px;
  }
}

.right-above .access {
  display: inline-block;
  /* height: 40px; */
  border-radius: 30px;
  background-color: #2f7445;
  color: #ffffff;
  position: relative;
  padding: 10px 30px;
  line-height: 1;
}
.right-above .access::after {
  font-family: "Font Awesome 5 Free";
  content: "";
  padding-left: 0.5em;
  color: #ffffff;
}

.navigation-top {
  top: 0;
  font-size: 14px;
  font-size: 0.875rem;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 7;
  background-color: #fff;
  padding-top: 4px;
}
@media screen and (min-width: 1100px) {
  .navigation-top {
    height: auto;
  }
}
@media only screen and (max-width: 768px) {
  .navigation-top {
    position: fixed !important;
    padding-top: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .sub-menu {
    padding: 0 5% 0 !important;
  }
}
.main-navigation ul ul {
  padding: 0;
}

@media screen and (-ms-high-contrast: none) and (min-width: 1110px) {
  .home #test-site-navigation.main-navigation > div > ul {
    display: inline-block;
  }
}
@media screen and (min-width: 2100px) {
  .parentCatLink > .sub-menu {
    justify-content: center !important;
  }
  .parentCatLink:first-child .sub-menu .ic {
    width: 20% !important;
  }
  .parentCatLink:nth-of-type(2) .sub-menu .ic {
    width: 12%;
  }
}
h2 {
  position: relative;
}

.section03 {
  top: 22px;
}

@keyframes bluepoint-a {
  0% {
    box-shadow: 0 0 0 0 #1F5AFE;
  }
  50% {
    box-shadow: 0px 0px 4px 1px #1F5AFE;
  }
  100% {
    box-shadow: 0 0 0 0 #1F5AFE;
  }
}
@keyframes orangepoint-a {
  0% {
    box-shadow: 0 0 0 0 #FF8E1E;
  }
  50% {
    box-shadow: 0px 0px 4px 1px #FF8E1E;
  }
  100% {
    box-shadow: 0 0 0 0 #FF8E1E;
  }
}
@keyframes pinkpoint-a {
  0% {
    box-shadow: 0 0 0 0 #FF7295;
  }
  50% {
    box-shadow: 0px 0px 4px 1px #FF7295;
  }
  100% {
    box-shadow: 0 0 0 0 #FF7295;
  }
}
.arrow::after {
  content: " ";
  width: 0;
  height: 0;
  /* padding-top: 11px; */
  /* margin-top: 1px; */
  /* vertical-align: top; */
  display: inline-block;
  position: absolute;
  top: 35%;
  margin-left: 6px;
  border-style: solid;
  border-width: 8px 0 8px 10px;
  border-color: transparent transparent transparent #015C21;
}
.arrow:hover::after {
  border-color: transparent transparent transparent #fff;
}

.spBtnLists {
  position: absolute;
  top: 6%;
  bottom: 0;
  margin: auto;
  right: 0;
  width: 107px;
  margin-right: 10px;
  width: 170px;
  display: block;
}
@media only screen and (max-device-width: 560px) {
  .spBtnLists {
    top: 0;
    width: 110px;
  }
}
.spBtnLists .menu-toggle {
  display: block;
  position: absolute;
  width: 100%;
  padding-top: 14%;
  max-width: 80px;
  height: 80px;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
@media only screen and (max-device-width: 560px) {
  .spBtnLists .menu-toggle {
    max-width: 50px;
    height: 50px;
  }
}

.hourLinkBtn {
  display: block;
  position: absolute;
  width: 100%;
  padding-top: 14%;
  max-width: 80px;
  height: 80px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
@media only screen and (max-device-width: 560px) {
  .hourLinkBtn {
    max-width: 50px;
    height: 50px;
  }
}
.hourLinkBtn::after {
  content: " ";
  width: 100%;
  height: 100%;
  padding-top: 14%;
  background-image: url(../../img/top_sp_menu_time.png);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  top: 0;
}
.hourLinkBtn:hover {
  opacity: 1;
}

.bold {
  font-weight: bold;
}

.mark {
  font-weight: bold;
  text-decoration: underline;
}

.colored {
  color: #015C21;
}

/**********************
フロントページ
********************/
.hero {
  position: relative;
}
@media screen and (min-width: 1100px) {
  .hero {
    margin-top: 120px;
  }
}
@media screen and (min-width: 769px) {
  .hero {
    margin-top: 170px;
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 768px) {
  .hero {
    margin-top: 16%;
  }
}
@media only screen and (max-device-width: 560px) {
  .hero {
    margin-top: 10%;
  }
}
.hero h1 {
  position: absolute;
  z-index: 111;
  color: #015C21;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6);
}
@media screen and (min-width: 935px) {
  .hero h1 {
    left: 3%;
    top: 55%;
  }
}
@media screen and (min-width: 860px) and (max-width: 935px) {
  .hero h1 {
    left: 1%;
    top: 50%;
  }
}
@media screen and (min-width: 768px) and (max-width: 860px) {
  .hero h1 {
    left: 1%;
    top: 44%;
  }
}
@media only screen and (max-width: 768px) {
  .hero h1 {
    left: 3px;
    top: 190px;
    font-size: 1.2em;
  }
}
@media only screen and (max-device-width: 560px) {
  .hero h1 {
    top: 170px;
    left: 4px;
  }
}
.hero h1 .maintitle {
  font-weight: normal;
  font-size: 1.2em;
  font-family: "M PLUS Rounded 1c";
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .hero h1 .maintitle {
    font-size: 1.1em;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .hero h1 .maintitle {
    font-size: 1.3em;
    margin-bottom: 0;
  }
}
@media only screen and (max-device-width: 560px) {
  .hero h1 .maintitle {
    font-size: 1em !important;
  }
}
.hero h1 .subtitle {
  font-weight: normal;
  font-size: 1em;
  font-family: "M PLUS Rounded 1c";
  margin-left: 20px;
  display: inline-block;
}
@media only screen and (max-device-width: 560px) {
  .hero h1 .subtitle {
    font-size: 0.8em !important;
  }
}
.hero .mainvisual {
  border-radius: 40px;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .hero .mainvisual {
    height: 295px;
    border-radius: 0;
  }
}
@media only screen and (max-device-width: 560px) {
  .hero .mainvisual {
    height: 240px;
  }
}
@media only screen and (max-width: 768px) {
  .hero .mainvisual img {
    height: 110%;
    object-fit: cover;
    object-position: center 36px;
  }
}
@media only screen and (max-device-width: 560px) {
  .hero .mainvisual img {
    height: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .hero .information {
    padding: 10px 20px;
  }
}
.hero .info1 {
  position: relative;
  z-index: 1111;
  margin-top: -28px;
  display: flex;
  border-radius: 30px;
  height: 50px;
  width: 57%;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .hero .info1 {
    width: 92%;
  }
}
@media only screen and (max-width: 768px) {
  .hero .info1 {
    /* position: relative; */
    z-index: 1111;
    margin-top: 20px;
    /* display: -webkit-box; */
    display: block;
    border-radius: 10px;
    height: auto;
    width: 100%;
  }
}
.hero .info1 .info_title {
  background-color: #2f7445;
  color: #ffffff;
  width: 15%;
  height: 100%;
  border-radius: 30px 0 0 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0 10px 0 16px;
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .hero .info1 .info_title {
    width: 100%;
    border-radius: 8px 8px 0 0;
    padding: 2px 0;
    margin-bottom: 0;
  }
}
.hero .info1 dl {
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 85%;
  height: 100%;
  border: 1px solid #015C21;
  border-radius: 0 50px 50px 0;
}
@media only screen and (max-width: 768px) {
  .hero .info1 dl {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 0 0 10px 10px;
  }
}
.hero .info1 dl dt {
  color: #015C21;
  padding: 0 10px;
}
@media screen and (min-width: 769px) {
  .hero .info1 dl dt {
    width: 20%;
  }
}
@media only screen and (max-width: 768px) {
  .hero .info1 dl dt {
    padding: 0;
  }
}
@media screen and (min-width: 769px) {
  .hero .info1 dl dd {
    width: 80%;
  }
}
.hero .info1 dl dd a {
  color: #1a1a1a;
  width: 100%;
  display: block;
}
@media screen and (min-width: 769px) {
  .hero .info1 dl dd a {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.hero .info1 dl dd a:hover {
  opacity: 1;
  font-weight: bold;
  text-decoration: underline;
  color: #015C21;
  transition: none !important;
}
.hero .reserveBtn {
  width: 100%;
  display: inline-block;
  text-align: center;
  background-color: #338D53;
  padding: 6px;
  color: #fff;
  margin: 30px 0;
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  font-weight: bold;
}
.hero .reserveBtn span {
  display: inline-block;
  padding-right: 10px;
}

.section01 {
  width: 100%;
  text-align: center;
  max-width: 1200px;
  padding: 0 100px;
  margin-bottom: 120px;
}
@media only screen and (max-width: 768px) {
  .section01 {
    padding: 0;
    margin-bottom: 60px;
  }
}
.section01 h2 {
  margin-bottom: 32px;
}
.section01 .guide-area {
  width: 100%;
  display: flex;
  justify-content: center;
}
.section01 .guide-area .guide-list {
  width: 32%;
  max-height: 180px;
  display: inline-block;
  border-left: 1px solid #aaa;
}
@media screen and (min-width: 769px) {
  .section01 .guide-area .guide-list {
    height: 100%;
  }
}
.section01 .guide-area .guide-list:last-child {
  border-right: 1px solid #aaa;
}
.section01 .guide-area .guide-list .guide-link {
  width: 100%;
  display: inline-block;
  height: 100%;
  color: #333333;
}
@media screen and (min-width: 769px) {
  .section01 .guide-area .guide-list .guide-link {
    padding: 20px 0 0;
  }
}
.section01 .guide-area .guide-list .guide-link:hover {
  transition: 0.3s;
  transition-timing-function: ease;
  opacity: 1;
}
.section01 .guide-area .guide-list .guide-link figure {
  display: inline-block;
  text-align: center;
  padding: 20px;
  height: 100px;
  /* display: -webkit-box; */
  /* display: flex; */
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: #fff;
  width: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}
@media only screen and (max-width: 768px) {
  .section01 .guide-area .guide-list .guide-link figure {
    height: 80px;
    width: 80px;
    margin-top: 10px;
  }
}
.section01 .guide-area .guide-list .guide-link figure img {
  width: 60%;
}
@media only screen and (max-width: 768px) {
  .section01 .guide-area .guide-list .guide-link figure img {
    width: 62%;
  }
}
.section01 .guide-area .guide-list .guide-link span {
  display: block;
  margin-bottom: 20px;
}
.section01 .guide-area .guide-list:first-child .guide-link:hover {
  background-color: #1F5AFE;
  color: #ffffff;
}
.section01 .guide-area .guide-list:nth-child(2) .guide-link:hover {
  background-color: #FF7295;
  color: #ffffff;
}
.section01 .guide-area .guide-list:nth-child(2) figure {
  padding: 26px 10px 10px;
}
.section01 .guide-area .guide-list:last-child .guide-link:hover {
  background-color: #FF8E1E;
  color: #ffffff;
}
.section01 .guide-area .guide-list:last-child figure {
  padding: 26px 10px 10px;
}

.section02 {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 80px;
  padding: 0 20px 0 100px;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .section02 {
    padding: 0 20px 0 73px;
  }
}
@media only screen and (max-width: 768px) {
  .section02 {
    padding: 0 20px;
  }
}
.section02 h2 {
  text-align: left;
  font-size: 1.85em;
}
@media screen and (min-width: 769px) {
  .section02 h2 {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 768px) {
  .section02 h2 {
    margin-bottom: 20px;
  }
}
@media only screen and (max-device-width: 560px) {
  .section02 h2 {
    font-size: 1.45em;
    margin-bottom: 10px;
  }
}
.section02 .imgBox {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .section02 .imgBox {
    margin-bottom: 7%;
  }
}
.section02_content {
  display: flex;
  justify-content: space-between;
}
.section02_content .txt {
  width: 48%;
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .section02_content .txt {
    width: 100%;
  }
}
.section02_content .txt p {
  line-height: 1.6;
}
.section02_content .imgBox {
  width: 56%;
  position: relative;
  margin-top: -30px;
}
.section02_content .moreInfo {
  display: inline-block;
  padding: 10px 26px 5px 10px;
  transition: 0.3s;
  transition-timing-function: ease;
  position: relative;
  left: 0;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", sans-serif;
  color: #015C21;
  font-weight: bold;
  border-bottom: 1px solid #015C21;
}
.section02_content .moreInfo:hover {
  opacity: 1;
  background-color: #015C21;
  color: #ffffff;
}

.section03 {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0 40px 0 50px;
  margin-top: 70px;
  margin-bottom: 60px;
}
@media only screen and (max-width: 768px) {
  .section03 {
    padding: 0 20px;
  }
}
.section03 h2 {
  position: relative;
  z-index: 10;
}
@media screen and (min-width: 769px) {
  .section03 h2 {
    padding-left: 20px;
  }
}
.section03 h2::before {
  content: " ";
  width: 130px;
  height: 140px;
  display: block;
  background-image: url(../../img/top_backimage01.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
  position: absolute;
  left: -52px;
  top: -34px;
  z-index: -1;
}
@media only screen and (max-width: 768px) {
  .section03 h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  .section03 h2::before {
    content: " ";
    width: 100%;
    height: 140px;
    display: block;
    background-image: url(../../img/top_sp_backimage01.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
@media only screen and (max-width: 768px) and (-ms-high-contrast: none) {
  .section03 h2::before {
    top: -60px;
  }
}
.section03_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .section03_content {
    display: block;
  }
}
.section03_content .credit {
  width: 55%;
  padding-top: 50px;
}
@media only screen and (max-width: 768px) {
  .section03_content .credit {
    width: 100%;
  }
}
.section03_content .credit .credit_inner {
  display: flex;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .section03_content .credit .credit_inner {
    display: block;
  }
}
.section03_content .credit .name {
  display: inline-block;
  width: 40%;
  margin-right: 20px;
}
@media only screen and (max-width: 768px) {
  .section03_content .credit .name {
    display: block;
    width: 100%;
    text-align: center;
  }
  .section03_content .credit .name span {
    display: inline-block;
    padding-left: 5px;
    font-size: 1.4em;
  }
}
.section03_content .credit .name div {
  font-size: 1.25em;
  font-weight: bold;
}
.section03_content .credit .address {
  display: inline-block;
  width: 50%;
  font-size: 0.9em;
  line-height: 1.4;
}
@media only screen and (max-width: 768px) {
  .section03_content .credit .address {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
  }
}
.section03_content .credit .address p {
  font-weight: bold;
}
.section03_content .credit .address p .telHeading {
  font-size: 22px;
  color: #015C21;
}
@media only screen and (max-width: 768px) {
  .section03_content .credit .address p .telHeading {
    font-weight: bold;
    font-size: 2.1em;
  }
}
.section03_content .credit .links {
  width: 100%;
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .section03_content .credit .links {
    margin-bottom: 40px;
    text-align: center;
  }
  .section03_content .credit .links a {
    display: inline-block !important;
    width: 70% !important;
    font-size: 1.1em;
    padding: 10px !important;
    text-align: center;
    margin: 0 0 20px 0 !important;
  }
}
@media only screen and (max-device-width: 560px) {
  .section03_content .credit .links a {
    width: 90% !important;
  }
}
.section03_content .credit .links .abouttime {
  position: relative;
  width: 35%;
  display: inline-block;
  padding: 3px;
  text-align: center;
  border-radius: 30px;
  background-color: #ff8e1e;
  color: #ffffff;
  font-size: 0.9em;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .section03_content .credit .links .abouttime {
    width: 45%;
  }
}
.section03_content .credit .links .abouttime::after {
  padding-left: 10px;
  font-family: "Font Awesome 5 Free";
  content: "";
  font-weight: 900;
}
.section03_content .credit .links .contactmail {
  position: relative;
  width: 30%;
  display: inline-block;
  padding: 3px;
  text-align: center;
  border-radius: 30px;
  background-color: #ff8e1e;
  color: #ffffff;
  font-size: 0.9em;
  margin-right: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .section03_content .credit .links .contactmail {
    width: 40%;
  }
}
.section03_content .credit .links .contactmail::after {
  padding-left: 10px;
  font-family: "Font Awesome 5 Free";
  content: "";
  font-weight: 900;
}
.section03_content .credit .links .aboutaccess {
  position: relative;
  width: 24%;
  display: inline-block;
  padding: 3px;
  text-align: center;
  border-radius: 30px;
  background-color: #015C21;
  color: #ffffff;
  font-size: 0.9em;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .section03_content .credit .links .aboutaccess {
    width: 34%;
  }
}
.section03_content .credit .links .aboutaccess::after {
  padding-left: 10px;
  font-family: "Font Awesome 5 Free";
  content: "";
  font-weight: 900;
}

.sinryou {
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .sinryou {
    width: 100%;
  }
}
.sinryou table {
  margin-left: 14px;
  margin: 0;
  border: none !important;
}
.sinryou table .thead {
  border-bottom: 1px solid #ccc;
}
.sinryou table .thead th {
  font-size: 1.125em;
}
@media screen and (min-width: 769px) {
  .sinryou table .thead th span {
    padding-left: 10px;
  }
}
.sinryou table .thead td {
  font-weight: bold;
}
.sinryou table th {
  width: 35%;
  text-align: left;
}
.sinryou table th .hour {
  color: #1a1a1a !important;
}
@media screen and (min-width: 769px) {
  .sinryou table th .hour {
    padding-left: 10px;
  }
}
.sinryou table tr, .sinryou table td, .sinryou table th {
  border: none;
  padding: 0;
}
.sinryou .caution {
  font-weight: bold;
  margin-bottom: 20px;
}
.sinryou .shinsatsu .thead th {
  color: #ff9600;
}
.sinryou .shinsatsu span {
  color: #ff9600;
}
.sinryou .shinsatsu tr:nth-child(2) {
  border-bottom: 1px solid #ccc;
}
.sinryou .shika .thead th {
  color: #3a8d57;
}
.sinryou .shika span {
  color: #3a8d57;
}
.sinryou .jsAreaOnly {
  display: none !important;
}
.sinryou .contentAreaOnly {
  display: block !important;
}

.section04 {
  height: 202px;
}
.section04 .map {
  width: 100%;
  height: 202px;
}

.section05 {
  display: flex;
  max-width: 1200px;
  padding: 0 72px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 70px;
}
@media only screen and (max-width: 768px) {
  .section05 {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .section05 {
    display: block;
  }
}
.section05 .newsHeading {
  width: 19.7%;
}
@media only screen and (max-width: 768px) {
  .section05 .newsHeading {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .section05 .newsHeading {
    width: 60%;
    display: flex;
    align-items: center;
  }
}
.section05 .newsHeading .newsTitle {
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .section05 .newsHeading .newsTitle {
    text-align: center;
    margin-bottom: 80px;
    margin-top: 50px;
    width: 100%;
    position: relative;
  }
  .section05 .newsHeading .newsTitle::before {
    content: " ";
    width: 90%;
    height: 140px;
    display: block;
    background-image: url(../../img/top_sp_backimage01.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
@media only screen and (max-device-width: 560px) {
  .section05 .newsHeading .newsTitle {
    margin-bottom: 60px;
  }
}
.section05 .newsBtn {
  display: inline-block;
  padding: 10px 26px 5px 10px;
  transition: 0.3s;
  transition-timing-function: ease;
  position: relative;
  font-family: "Yu Mincho Light", "YuMincho", "Yu Mincho", "游明朝体", sans-serif;
  color: #015C21;
  font-weight: bold;
  border-bottom: 1px solid #015C21;
}
@media screen and (min-width: 1110px) {
  .section05 .newsBtn:hover {
    opacity: 1;
    background-color: #015C21;
    color: #ffffff;
    border-bottom: none;
  }
}
.section05 .newsBtn.arrow {
  transition: 0.3s;
}
@media only screen and (max-width: 768px) {
  .section05 .newsBtn.arrow:hover::after {
    border-color: transparent transparent transparent #338D53;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .section05 .newsBtn.arrow::after {
    top: 20% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .section05 .newsBtn {
    padding: 0;
    border-bottom: none;
    margin-left: 30px;
  }
}
.section05 .news {
  width: 80%;
}
@media only screen and (max-width: 768px) {
  .section05 .news {
    width: 100%;
  }
}
.section05 .tabBox {
  display: flex;
  width: 41%;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .section05 .tabBox {
    width: 60%;
  }
}
@media only screen and (max-width: 768px) {
  .section05 .tabBox {
    width: 90%;
  }
}
.section05 .tab {
  display: inline-block;
  flex-grow: 1;
  padding: 5px;
  list-style: none;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer;
}
.section05 .tab1 {
  background-color: #338D53;
  color: #ffffff;
}
.section05 .tab2 {
  background-color: #ccc;
}
.section05 .tab2 .day {
  color: #666666 !important;
}
.section05 .panel {
  /*非表示にさせます*/
  display: none;
  padding: 20px 20px 10px 20px;
  background: #fff;
}
.section05 .panel.show {
  display: block;
}
.section05 .panel.show.tab1 {
  border: solid 2px #338D53;
}
.section05 .panel.show.tab2 {
  border: solid 2px #ccc;
}
.section05 .infolist_content {
  border-bottom: 1px dotted #222;
  line-height: 1.4;
  margin-bottom: 10px;
}
@media only screen and (max-width: 768px) {
  .section05 .infolist_content {
    display: block;
  }
}
.section05 .infolist_content .day {
  width: 23%;
  color: #015C21;
  font-weight: bold;
  display: inline-block;
}
@media only screen and (max-width: 768px) {
  .section05 .infolist_content .day {
    width: 100%;
  }
}
.section05 .infolist_content dd {
  width: 70%;
  display: inline-block;
}
@media only screen and (max-width: 768px) {
  .section05 .infolist_content dd {
    width: 100%;
  }
}
.section05 .infolist_content .infolist_txt {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1a1a1a;
  transition: 0.2s;
  display: inline-block;
  width: 100%;
  font-size: 0.95em;
  vertical-align: middle;
}
.section05 .infolist_content .infolist_txt:hover {
  opacity: 1;
  color: #015C21;
  text-decoration: underline;
}
@media only screen and (max-width: 768px) {
  .section05 .infolist_content .infolist_txt {
    width: 100%;
  }
}
.section05 .linkWrap {
  width: 100%;
  text-align: right;
}
@media only screen and (max-width: 768px) {
  .section05 .linkWrap {
    margin: 10px 0 50px;
  }
}
.section05 .none_article {
  color: #4a4a4a;
}

.menu-social-container .menu {
  display: flex;
}

.footer-navigation .js .menu-toggle {
  display: none;
}

.copyright {
  margin-top: 30px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .menu-social-container #social-menu {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .menu-social-container #social-menu > .menu-item {
    width: 100%;
    margin-bottom: 40px;
  }
}
.menu-social-container #social-menu > .menu-item:first-child > a {
  color: #FF8E1E;
}
.menu-social-container #social-menu > .menu-item:first-child > a::before {
  background-color: #ffe5cc;
}
.menu-social-container #social-menu > .menu-item:first-child .sub-menu li a:hover {
  color: #FF8E1E;
}
.menu-social-container #social-menu > .menu-item:nth-child(2) > a {
  color: #1F5AFE;
}
.menu-social-container #social-menu > .menu-item:nth-child(2) > a::before {
  background-color: #bdcdfe;
}
.menu-social-container #social-menu > .menu-item:nth-child(2) .sub-menu li a:hover {
  color: #1F5AFE;
}
.menu-social-container #social-menu > .menu-item:nth-child(3) > a {
  color: #FF7295;
}
.menu-social-container #social-menu > .menu-item:nth-child(3) > a::before {
  background-color: #fec8d6;
}
.menu-social-container #social-menu > .menu-item:nth-child(3) .sub-menu li a:hover {
  color: #FF7295;
}
.menu-social-container #social-menu > .menu-item a {
  font-size: 1.4em;
  position: relative;
  z-index: 1;
  color: #222;
}
@media only screen and (max-width: 768px) {
  .menu-social-container #social-menu > .menu-item a {
    margin-right: 2em;
  }
}
@media only screen and (max-device-width: 560px) {
  .menu-social-container #social-menu > .menu-item a {
    margin-right: 0;
  }
}
.menu-social-container #social-menu > .menu-item a::before {
  content: " ";
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.menu-social-container #social-menu > .menu-item .sub-menu li a:hover {
  opacity: 1;
  text-decoration: underline;
}
@media only screen and (max-width: 768px) {
  .menu-social-container #social-menu .shinsatsu-lists ul {
    display: flex;
    flex-wrap: wrap;
  }
}
@media all and (-ms-high-contrast: none) {
  .menu-social-container #social-menu .shinsatsu-lists ul {
    display: block;
  }
}
@media only screen and (max-device-width: 560px) {
  .menu-social-container #social-menu .shinsatsu-lists ul {
    display: block;
  }
}
@media screen and (min-width: 769px) {
  .menu-social-container #social-menu .list-heading {
    margin-right: 5%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .menu-social-container #social-menu .list-heading {
    margin-right: 2%;
    width: 32%;
  }
}
.menu-social-container #social-menu .list-heading > a {
  display: block;
  padding: 10px 10px 10px 14px;
  text-shadow: 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff;
  cursor: default;
}
.menu-social-container #social-menu .list-heading > a:hover {
  opacity: 1;
}
.menu-social-container #social-menu .sub-menu li {
  line-height: 2;
}

#social-menu li ul li a {
  font-size: 1em !important;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  margin: 0 auto;
  height: auto;
  overflow: visible;
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide img {
  width: 100%;
  border-radius: 40px;
}
@media only screen and (max-width: 768px) {
  .swiper-slide img {
    border-radius: 0 !important;
  }
}

.mainvisual {
  overflow-x: hidden;
}

#slideL {
  z-index: 3333;
  cursor: pointer;
  position: fixed;
  right: -483px;
  width: 530px;
  height: 380px;
  padding: 0 0 0 40px;
  margin-right: 483px;
  background-color: rgba(255, 255, 255, 0.8);
  top: 40%;
  transition: 0.3s;
  transition-timing-function: ease;
}
#slideL .tate {
  color: #ffffff;
  position: absolute;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  left: 0;
  background-color: #338D53;
  display: block;
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  font-weight: bold;
  height: 100%;
  padding: 140px 9px;
}
#slideL .tateIcon {
  padding-top: 14px;
}
@media all and (-ms-high-contrast: none) {
  #slideL .tateIcon::before {
    position: absolute;
    left: 9px;
  }
  #slideL .tateIcon.fa-chevron-circle-right::before {
    content: "" !important;
  }
}
@media all and (-ms-high-contrast: none) {
  #slideL .tate {
    width: 9%;
    padding: 100px 12px;
  }
}

.underpage #slideL {
  margin-right: 0;
}

#slideL .slide-inner {
  padding: 5px 20px 10px;
  height: 100%;
  cursor: default;
}
#slideL .slide-inner p {
  margin: 0;
}
#slideL .slide-inner .denwa {
  color: #015C21;
  font-size: 1em;
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  font-weight: bold;
  margin-right: 10px;
}
#slideL .slide-inner .tel-txt {
  font-size: 1em;
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}
#slideL .slide-inner .tel-num {
  font-size: 1.4em;
  font-weight: bold;
  color: #015C21;
}
#slideL .slide-inner .sinryouHeading {
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  font-size: 1em;
  font-weight: bold;
  color: #015C21;
  line-height: 1;
  margin-bottom: 5px;
}
#slideL .slide-inner .sinryou {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  #slideL .slide-inner .sinryou {
    width: 100%;
  }
}
#slideL .slide-inner .sinryou .shinsatsu, #slideL .slide-inner .sinryou .shika {
  margin-left: 14px;
  margin: 0;
  border: 1px solid #ccc !important;
}
#slideL .slide-inner .sinryou .shinsatsu .thead, #slideL .slide-inner .sinryou .shika .thead {
  background-color: rgba(46, 213, 105, 0.2) !important;
  border-bottom: 1px solid #ccc;
}
#slideL .slide-inner .sinryou .shinsatsu .thead th, #slideL .slide-inner .sinryou .shika .thead th {
  font-size: 1.125em;
  text-align: center;
}
#slideL .slide-inner .sinryou .shinsatsu .thead th span, #slideL .slide-inner .sinryou .shika .thead th span {
  font-size: 0.9em;
  padding-left: 10px;
  color: #3a8d57 !important;
}
#slideL .slide-inner .sinryou .shinsatsu .thead td, #slideL .slide-inner .sinryou .shika .thead td {
  font-weight: bold;
  font-size: 0.8em;
}
#slideL .slide-inner .sinryou .shinsatsu tbody, #slideL .slide-inner .sinryou .shika tbody {
  background-color: #fff;
}
#slideL .slide-inner .sinryou .shinsatsu th, #slideL .slide-inner .sinryou .shika th {
  width: 35%;
  text-align: left;
  border-right: 1px solid #ccc;
}
#slideL .slide-inner .sinryou .shinsatsu th .hour, #slideL .slide-inner .sinryou .shika th .hour {
  color: #1a1a1a !important;
  padding-left: 10px;
  font-size: 0.9em;
  text-align: left;
  display: inline-block;
  width: 100%;
}
#slideL .slide-inner .sinryou .shinsatsu td, #slideL .slide-inner .sinryou .shika td {
  padding: 5px 13px;
}
#slideL .slide-inner .sinryou .shinsatsu td span, #slideL .slide-inner .sinryou .shika td span {
  color: #000 !important;
}
#slideL .slide-inner .sinryou .shinsatsu td.first-td, #slideL .slide-inner .sinryou .shika td.first-td {
  margin-left: 10px;
}
#slideL .slide-inner .sinryou .shinsatsu tr, #slideL .slide-inner .sinryou .shinsatsu td, #slideL .slide-inner .sinryou .shinsatsu th, #slideL .slide-inner .sinryou .shika tr, #slideL .slide-inner .sinryou .shika td, #slideL .slide-inner .sinryou .shika th {
  padding: 2px;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  #slideL .slide-inner .sinryou .shinsatsu tr, #slideL .slide-inner .sinryou .shinsatsu td, #slideL .slide-inner .sinryou .shinsatsu th, #slideL .slide-inner .sinryou .shika tr, #slideL .slide-inner .sinryou .shika td, #slideL .slide-inner .sinryou .shika th {
    padding: 2px 10px;
  }
}
#slideL .slide-inner .sinryou .shinsatsu .contentAreaOnly, #slideL .slide-inner .sinryou .shika .contentAreaOnly {
  display: none !important;
}
#slideL .slide-inner .sinryou .shinsatsu .jsAreaOnly, #slideL .slide-inner .sinryou .shika .jsAreaOnly {
  display: block !important;
  font-weight: bold;
}
#slideL .slide-inner .sinryou .caution {
  font-weight: bold;
  font-size: 0.8em;
}
#slideL .slide-inner .btnWrap {
  width: 100%;
  text-align: center;
  margin: 5px 0;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  #slideL .slide-inner .btnWrap {
    margin: 10px 0;
  }
}
#slideL .slide-inner .btnWrap .shinsatsuBtn {
  display: inline-block;
  width: 80%;
  padding: 8px;
  background-color: green;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  font-size: 0.9em;
  cursor: pointer;
}
#slideL .slide-inner .btnWrap .shinsatsuBtn .btnIcon {
  padding-left: 6px;
}

.nav-icon {
  width: 50px;
  height: 50px;
  background-color: aqua;
  display: block;
}

@media only screen and (max-width: 768px) {
  .menu-side-container #menu-side {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .menu-side-container #menu-side > .menu-item {
    width: 100%;
    margin-bottom: 40px;
  }
}
.menu-side-container #menu-side > .menu-item:first-child > a {
  color: #1F5AFE;
}
.menu-side-container #menu-side > .menu-item:first-child > a::before {
  background-color: #bdcdfe;
}
.menu-side-container #menu-side > .menu-item:nth-child(2) > a {
  color: #FF7295;
}
.menu-side-container #menu-side > .menu-item:nth-child(2) > a::before {
  background-color: #fec8d6;
}
.menu-side-container #menu-side > .menu-item:nth-child(3) > a {
  color: #FF8E1E;
}
.menu-side-container #menu-side > .menu-item:nth-child(3) > a::before {
  background-color: #ffe5cc;
}
.menu-side-container #menu-side > .menu-item > a {
  display: block;
  font-size: 1.2em;
  position: relative;
  padding: 10px;
  z-index: 1;
  text-shadow: 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff;
}
.menu-side-container #menu-side > .menu-item > a::before {
  content: " ";
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.menu-side-container #menu-side .shinsatsu-lists {
  margin-bottom: 20px;
}
.menu-side-container #menu-side .sub-menu li a {
  display: block;
  font-size: 1em;
  position: relative;
  padding: 5px 10px;
  z-index: 1;
  color: #222;
  width: 100%;
}
.menu-side-container #menu-side .sub-menu li a:hover {
  background-color: #bdcdfe;
  color: #ffffff;
  opacity: 1;
  transition: 0.1s;
  transition-timing-function: ease;
}
.menu-side-container #menu-side .sub-menu li a:hover:after {
  color: #ffffff;
}
.menu-side-container #menu-side .sub-menu li a:after {
  content: "▶";
  font-size: 0.7em;
  color: #bdcdfe;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.menu-side-container #menu-side .sub-menu .current_page_item {
  background-color: #bdcdfe;
}
.menu-side-container #menu-side .sub-menu .current_page_item a {
  color: #ffffff;
  position: relative;
}
.menu-side-container #menu-side .sub-menu .current_page_item a:after {
  color: #fff;
}

.header-area .header-above {
  padding: 12px 12px 0;
}
@media screen and (min-width: 769px) {
  .header-area .header-above {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
    margin-bottom: 10px;
    align-items: baseline;
  }
}
@media only screen and (max-device-width: 560px) {
  .header-area .header-above {
    padding: 0 12px;
  }
}
.header-area .header-above .header-left {
  width: 40%;
  position: relative;
  z-index: 99999;
}
@media only screen and (max-width: 768px) {
  .header-area .header-above .header-left {
    top: 4px;
    width: 60%;
  }
}
.header-area .header-above .tel {
  font-size: 2.5em;
  font-weight: bold;
  color: #2f7445;
  position: relative;
  margin-bottom: 0;
  margin-right: 34px;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .header-area .header-above .tel {
    font-size: 1.5em;
  }
}
@media only screen and (max-width: 768px) {
  .header-area .header-above .tel {
    margin-right: 0;
    font-size: 2.2em;
  }
}
@media only screen and (max-device-width: 560px) {
  .header-area .header-above .tel {
    font-size: 1.6em;
  }
}
.header-area .header-above .tel::before {
  font-family: "Font Awesome 5 Free";
  content: "";
  font-size: 0.8em;
  margin-right: 10px;
}
.header-area .header-bottom {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}
.header-area .right-above {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .header-area .right-above {
    width: 60%;
  }
}
@media only screen and (max-width: 768px) {
  .header-area .right-above {
    height: 0;
  }
}
.header-area .header-subtitle {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  font-size: 0.9em;
}
@media only screen and (max-width: 768px) {
  .header-area .header-subtitle {
    display: none;
  }
}
.header-area #top-menu {
  text-align: center;
}
.header-area .logo {
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .header-area .logo {
    width: 85%;
    margin-bottom: 0;
  }
}
.header-area .dropdown-toggle {
  display: none;
}
.header-area .main-navigation {
  display: block;
  max-width: 100% !important;
}
@media only screen and (max-width: 768px) {
  .header-area .main-navigation {
    height: 0;
  }
}
@media screen and (min-width: 769px) {
  .header-area .main-navigation li {
    width: calc(98% / 4);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .header-area .main-navigation li {
    width: calc(96% / 4);
  }
}
@media only screen and (max-width: 768px) {
  .header-area .main-navigation li {
    position: relative;
    width: 100%;
    text-align: left;
    padding: 2%;
  }
}
.header-area .main-navigation li > a {
  width: 100%;
  padding: 6px;
  border-left: 0;
  position: relative;
}
@media screen and (min-width: 769px) {
  .header-area .main-navigation li > a {
    padding: 10px 10px 0px 54px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .header-area .main-navigation li > a {
    padding: 10px !important;
  }
}
.header-area nav {
  width: 100% !important;
}
@media screen and (min-width: 769px) {
  .header-area .sub-menu li a {
    height: 100%;
    padding: 20px 5px 20px !important;
  }
}

.header-area .main-navigation > .menu-top-container > #top-menu > li:first-child > a::before {
  padding-top: 13%;
  top: -1px;
  left: -34%;
}

.header-area .main-navigation > .menu-top-container > #top-menu > li:nth-of-type(2) > a::before {
  padding-top: 16%;
  top: -5px;
  left: -22%;
}

.header-area .main-navigation > .menu-top-container > #top-menu > li:nth-of-type(3) > a::before {
  padding-top: 11%;
  left: -34%;
  top: 7px;
}

#header-underpage .main-navigation > .menu-top-container > #top-menu > li:first-child > a::before {
  width: 34px;
  height: 34px;
  padding-top: 13%;
  top: -1px;
  left: 19%;
}

#header-underpage .main-navigation > .menu-top-container > #top-menu > li:nth-of-type(2) > a::before {
  padding-top: 26%;
  top: -19px;
  left: 28%;
}

#header-underpage .main-navigation > .menu-top-container > #top-menu > li:nth-of-type(3) > a::before {
  width: 34px;
  height: 34px;
  padding-top: 11%;
  left: 12%;
  top: 7px;
}

#header-underpage .menu-top-container > ul {
  display: flex;
}

.header-area .main-navigation > .menu-top-container > #top-menu > li:last-child > a {
  padding: 10px 0 0;
}

@media screen and (min-width: 1110px) {
  .main-navigation > .menu-top-container > #top-menu > li > a::before {
    content: " ";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    display: block;
    position: absolute;
  }
}
/**********************
おしらせ
********************/
.newspage {
  max-width: 1240px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 1110px) {
  .newspage {
    margin-top: 250px;
  }
}
@media screen and (min-width: 769px) {
  .newspage {
    margin-top: 230px;
  }
}
@media only screen and (max-width: 768px) {
  .newspage {
    margin-top: 30%;
    padding: 20px;
  }
}
@media screen and (min-width: 769px) {
  .newspage #primary {
    float: left;
    width: 60%;
  }
}
@media only screen and (max-width: 768px) {
  .newspage #primary {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 769px) {
  .newspage #secondary {
    top: 250px;
    float: right;
    width: 30%;
  }
}
.newspage #menu-newsmenu li a {
  display: block;
  font-size: 1em;
  position: relative;
  padding: 10px 20px 10px 10px;
  z-index: 1;
  color: #222;
  width: 100%;
}
.newspage #menu-newsmenu li.catLink a:hover {
  background-color: #015C21;
  color: #ffffff;
  opacity: 1;
  transition: 0.1s;
  transition-timing-function: ease;
}
.newspage #menu-newsmenu li.catLink a:hover:after {
  color: #ffffff;
}
.newspage #menu-newsmenu li.catLink a:after {
  content: " ";
  width: 0;
  height: 0;
  display: inline-block;
  position: absolute;
  top: 35%;
  right: 10px;
  margin-left: 6px;
  border-style: solid;
  border-width: 8px 0 8px 10px;
  border-color: transparent transparent transparent #015C21;
}
.newspage #menu-newsmenu .catHeading > a {
  color: #015C21;
  font-weight: bold;
  cursor: default;
  text-align: center;
  font-size: 1.8em;
  line-height: 12px;
  margin-bottom: 10px;
}
.newspage #menu-newsmenu .catHeading > a:hover {
  opacity: 1;
}
.newspage #menu-newsmenu .current-menu-item {
  background-color: #015C21;
}
.newspage #menu-newsmenu .current-menu-item a {
  color: #ffffff;
  position: relative;
}
.newspage #menu-newsmenu .current-menu-item a:after {
  color: #fff;
}
.newspage .entry-meta {
  margin-bottom: 15px;
}
.newspage .post-categories {
  display: inline-block;
  margin-left: 20px;
}
@media only screen and (max-width: 768px) {
  .newspage .post-categories {
    margin-left: 0;
  }
}
.newspage .post-categories li {
  display: inline-block;
  display: inline-block;
  background-color: #ccc;
  padding: 2px 8px;
  margin: 0 3px;
  border-radius: 15px;
  color: #222;
  font-size: 0.8em;
}
.newspage .post-categories li.important {
  background-color: #c7dc68;
}
.newspage .post-categories li.medical {
  background-color: rgba(199, 220, 104, 0.6);
}
.newspage .post-categories li.other {
  background-color: rgba(199, 220, 104, 0.3);
}
.newspage .articleHeading .articleHeading a {
  display: inline-block;
  background-color: #015C21;
  padding: 3px 10px;
  border-radius: 15px;
  color: #fff;
  font-size: 0.8em;
  margin-right: 5px;
}
.newspage .articleHeading .entry-title {
  margin-right: 15px;
  margin-bottom: 0;
  color: #015C21;
  font-size: 1.7em;
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  text-decoration: none;
  margin-left: -2px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 100%;
}
.newspage .articleHeading a {
  color: #2f7445;
  text-decoration: none;
  margin-left: -2px;
  font-size: 1.2em;
}
.newspage .moreTxt {
  color: #015C21;
  box-shadow: none;
}
.newspage img {
  margin-bottom: 20px;
  display: block;
}

.no-results {
  padding: 40px;
}

.noarticle_title {
  color: #015C21;
  font-size: 1.5em;
  position: relative;
  z-index: 1;
}
.noarticle_title::before {
  content: " ";
  width: 130px;
  height: 140px;
  display: block;
  background-image: url(../../img/top_backimage01.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
  position: absolute;
  left: -52px;
  top: -34px;
  z-index: -1;
}

.archive .site-content, .single .site-content {
  border-bottom: 10px solid #adcbb8;
}

/**********************
下層固定ページ
********************/
.underpage {
  padding-top: 9%;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .underpage {
    padding-top: 19%;
  }
}
@media only screen and (max-device-width: 560px) {
  .underpage {
    padding-top: 17%;
  }
}
.underpage .underpagewrap {
  max-width: 1200px;
  width: 100%;
  padding: 0 85px;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .underpage .underpagewrap {
    padding: 0 10px;
  }
}
.underpage .pageMainVisual img {
  display: block;
  margin: auto;
  height: 245px;
  /* max-height: 300px; */
  width: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 768px) {
  .underpage .pageMainVisual img {
    height: 200px;
  }
}
@media only screen and (max-device-width: 560px) {
  .underpage .pageMainVisual img {
    height: 120px;
  }
}
.underpage .page-heading {
  position: relative;
  margin: auto;
  bottom: 70px;
  right: 0;
  left: 0;
  width: 50%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 6px 3px #a8c7f0;
  margin-bottom: -110px;
}
@media only screen and (max-width: 768px) {
  .underpage .page-heading {
    bottom: 100px;
    width: 90%;
    margin-bottom: -30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1110px) {
  .underpage .page-heading {
    width: 80%;
  }
}
@media only screen and (max-device-width: 560px) {
  .underpage .page-heading {
    bottom: 40px;
  }
}
.underpage .page-heading h1 {
  color: #1F5AFE;
}
.underpage .page-heading span {
  display: block;
  margin: 10px 0;
  color: #4a4a4a;
  font-size: 0.54em;
  font-weight: normal;
  font-family: "游ゴシック Medium", YuGothic, "Yu Gothic", sans-serif, "ヒラギノ角ゴシック", "Hiragino Sans";
  line-height: 1.85;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
.underpage .point2 {
  border: 1px solid #a8c7f0;
  box-shadow: 4px 4px 6px 0px #a8c7f0;
  border-radius: 20px;
  padding: 30px;
  margin: 30px 0 80px;
}
.underpage .point2 .section_content {
  margin: 10px;
}
.underpage .point2 .pointHeading {
  font-size: 1.3em !important;
  color: #1F5AFE;
}
.underpage p {
  z-index: 11;
  position: relative;
}
.underpage .caution {
  margin: 0;
  font-size: 0.9em;
}
.underpage .caution.zentai {
  margin-bottom: 50px;
}
.underpage li {
  line-height: 1.5;
}
.underpage .lists {
  list-style-type: none;
  list-style-position: inside;
  padding-left: 4%;
  overflow: hidden;
  width: 100%;
}
.underpage .lists li::before {
  position: relative;
  top: -2px;
  left: -8px;
  display: inline-block;
  width: 4px;
  height: 4px;
  content: "";
  border-radius: 100%;
  background: #333;
}
.underpage .lists.disc2 li::before {
  width: 7px;
  height: 7px;
  background: #fff;
  border: 1px solid #000;
}
.underpage h2 {
  color: #4a4a4a;
}
.underpage .num {
  list-style-type: decimal;
  list-style-position: inside;
}
.underpage .num.flow {
  list-style-position: outside;
  margin-bottom: 50px;
  padding-left: 22px;
  font-size: 20px;
  color: #1F5AFE;
  font-weight: bold;
}
.underpage .num.flow li {
  margin-bottom: 15px;
}
.underpage .num.flow li span {
  font-size: 16px;
  color: #000;
  position: relative;
  font-weight: normal;
  display: inline-block;
  vertical-align: text-top;
  position: relative;
}
.underpage .num.flow li:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  margin: 10px 0 7px;
  border-style: solid;
  border-width: 13px 12px 0 12px;
  border-color: rgba(31, 90, 254, 0.2) transparent transparent transparent;
}
.underpage .num.flow li:last-child::after {
  display: none;
}
.underpage figcaption {
  font-style: normal;
  font-size: 0.9em;
}
.underpage .captitle {
  font-weight: bold;
}
.underpage .sectionlists {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 -10px 100px;
  position: relative;
  z-index: 888;
}
@media all and (-ms-high-contrast: none) {
  .underpage .sectionlists {
    justify-content: baseline;
  }
}
@media only screen and (max-width: 768px) {
  .underpage .sectionlists {
    text-align: center;
    margin: 0 auto;
    display: block;
    margin-bottom: 100px;
  }
  .underpage .sectionlists a {
    width: 90%;
  }
}
.underpage .sectionBtn {
  display: inline-block;
  border-radius: 50px;
  text-align: center;
  padding: 0.4em 2em;
  background-color: #0075c2;
  color: #ffffff !important;
  box-shadow: 0px 4px 9px 0px rgba(0, 117, 194, 0.5);
  transition: 0.3s;
  transition-timing-function: ease;
}
@media screen and (min-width: 769px) {
  .underpage .sectionBtn {
    width: 30%;
  }
}
@media only screen and (max-width: 768px) {
  .underpage .sectionBtn {
    margin: 10px;
    padding: 0.6em;
  }
}
.underpage .sectionBtn:hover {
  opacity: 1;
  transform: translateY(-2px);
}
.underpage .lowersection {
  margin-bottom: 100px;
}
.underpage .sectionHeading {
  position: relative;
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  z-index: 10;
  font-size: 1.7em;
  color: #015C21;
  padding: 6%;
}
@media screen and (min-width: 769px) {
  .underpage .sectionHeading {
    margin-top: 130px;
    margin-bottom: 20px;
    padding: 40px 20px 0 0;
  }
}
@media only screen and (max-width: 768px) {
  .underpage .sectionHeading {
    margin: 70px 0 20px 0;
    text-align: center;
  }
  .underpage .sectionHeading::before {
    content: " ";
    width: 100%;
    height: 140px;
    display: block;
    background-image: url(../../img/top_sp_backimage01.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
}
@media only screen and (max-device-width: 560px) {
  .underpage .sectionHeading {
    margin-bottom: 30px;
    padding: 6% 0;
  }
}
.underpage .sectionHeading::before {
  content: " ";
  width: 157px;
  height: 216px;
  display: block;
  background-image: url(../../img/top_backimage01.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
  position: absolute;
  top: -24px;
  left: -82px;
  font-size: 1.5em;
  z-index: -1;
}
.underpage .subsectionHeading {
  font-size: 0.8em;
  display: inline-block;
  margin-left: 20px;
}
.underpage .contentHeading {
  position: relative;
  font-weight: bold;
  margin: 25px 0 0 2px;
  font-size: 1.1em;
}
.underpage .contentHeading::before {
  content: " ";
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 1px;
}
.underpage .contentHeading.fade_on::before {
  animation-name: point-a;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}
.underpage h3 {
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  font-size: 1.5em;
  position: relative;
  z-index: 140;
  margin: 40px 0 20px 0;
}
@media only screen and (max-width: 768px) {
  .underpage h3 {
    margin: 70px 0 20px 0;
  }
}
.underpage h3::after {
  content: "";
  transition: 1s;
  transition-delay: 0.1s;
  transition-timing-function: ease;
  width: 100%;
  height: 4px;
  transform: scale(0, 1);
  background-color: rgba(168, 199, 240, 0.3);
  display: block;
  transform-origin: left;
}
.underpage h3.fade_on::after {
  transform: scale(1, 1);
}
.underpage .site-main a {
  font-weight: bold;
  display: inline-block;
}
.underpage .site-main img {
  object-fit: contain;
  position: relative;
}
.underpage .site-main .mb {
  margin-bottom: 100px;
}
@media only screen and (max-width: 768px) {
  .underpage .site-main .mb {
    margin-bottom: 80px;
  }
}
.underpage .kamokuTitle {
  font-weight: bold;
  color: #1F5AFE;
  font-size: 1.3em;
  border-bottom: 3px solid rgba(168, 199, 240, 0.3);
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
}
@media only screen and (max-width: 768px) {
  .underpage .section02_content {
    display: block;
  }
}
.underpage img {
  display: block;
}
.underpage .profile {
  text-align: center;
}
.underpage .profile figure {
  display: inline-block;
  margin-bottom: 0;
}
.underpage .profile figure img {
  width: 90%;
  margin: 0;
}
.underpage .profile .wp-caption-text {
  text-align: left;
  font-weight: bold;
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  line-height: 1.5;
}
@media screen and (min-width: 769px) {
  .underpage .profile .wp-caption-text {
    margin-bottom: 0;
  }
}
.underpage .tleft {
  text-align: left !important;
}
.underpage .section_content {
  margin-bottom: 80px;
  position: relative;
  z-index: 269;
  display: block;
  overflow: hidden;
}
@media screen and (min-width: 1110px) {
  .underpage .section_content {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .underpage .section_content {
    margin-bottom: 40px;
  }
}
.underpage .section_content .content_left {
  width: 100%;
}
@media screen and (min-width: 1110px) {
  .underpage .section_content .content_left {
    width: 58%;
    float: left;
  }
}
.underpage .section_content .content_right {
  width: 100%;
  padding: 0 0 20px;
}
@media screen and (min-width: 1110px) {
  .underpage .section_content .content_right {
    width: 42%;
    float: right;
    padding: 0 0 0 30px;
  }
}
@media only screen and (max-width: 768px) {
  .underpage .section_content .content_right {
    margin: auto;
  }
}
@media only screen and (max-device-width: 560px) {
  .underpage .section_content .content_right {
    padding: 0;
  }
}
.underpage .section_content .content_right .flex {
  display: block;
}
@media only screen and (max-width: 768px) {
  .underpage .section_content .content_right .flex {
    display: flex;
  }
}
.underpage .section_content .content_right img, .underpage .section_content .content_right .name {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
}
.underpage .section_content.block {
  display: block;
}
.underpage .headingImg {
  width: 80%;
  margin: 0 auto;
  margin-bottom: -50px;
}
@media only screen and (max-width: 768px) {
  .underpage .headingImg {
    margin-bottom: 80px;
  }
}
.underpage .headingImg img {
  width: 100%;
  object-fit: contain;
}
.underpage .link {
  font-size: 1em;
  text-decoration: underline;
  border-bottom: none !important;
}
.underpage .point {
  position: relative;
  z-index: 111;
  background-color: #f4f7ff;
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0;
}
.underpage .point .section_content {
  margin: 10px;
}
.underpage .pointHeading {
  font-size: 1.3em;
  font-weight: bold;
}
.underpage .underpage-content {
  border-bottom: 10px solid #adcbb8;
}
@media screen and (min-width: 769px) {
  .underpage .underpage-content {
    padding-bottom: 140px;
  }
}
@media only screen and (max-width: 768px) {
  .underpage .outpatient.underpage-content, .underpage .guide.underpage-content, .underpage #hospitalization.underpage-content {
    padding-bottom: 100px;
  }
}
.underpage .flex {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.underpage .flex > img {
  width: 45%;
}
.underpage .flexlist {
  display: flex;
}
@media only screen and (max-device-width: 560px) {
  .underpage .flexlist {
    flex-wrap: wrap;
  }
}
.underpage .flexlist .imgBox {
  width: 30%;
  text-align: center;
}
@media only screen and (max-device-width: 560px) {
  .underpage .flexlist .imgBox {
    width: 100%;
  }
}
.underpage .flexlist figure {
  width: 30%;
}
@media only screen and (max-device-width: 560px) {
  .underpage .flexlist figure {
    width: 48%;
  }
}
.underpage .flexlist figure img {
  width: 100%;
}
.underpage .flexlist .listcontent_left {
  width: 70%;
}
@media only screen and (max-device-width: 560px) {
  .underpage .flexlist .listcontent_left {
    width: 100%;
  }
}
.underpage .flexlist .listcontent_right {
  width: 25%;
}
@media screen and (min-width: 769px) {
  .underpage .flexlist .listcontent_right {
    margin-left: 5%;
  }
}
@media only screen and (max-device-width: 560px) {
  .underpage .flexlist .listcontent_right {
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
  }
}
.underpage .flexlist .listcontent_right img {
  width: 100%;
}
.underpage .pointFlex {
  display: flex;
  width: 100%;
}
.underpage .pointFlex .left {
  width: 80%;
}
.underpage .pointFlex .right {
  width: 20%;
  padding-left: 10px;
}
.underpage .mejs-container {
  border: 1px solid #015C21;
}

@media screen and (min-width: 769px) {
  #guide .underpagewrap, #medical-checkup .underpagewrap, #regional-activity .underpagewrap, #recruit .underpagewrap {
    margin-top: -100px;
  }
}

.breadcrumb {
  font-weight: bold;
  padding: 5px 0;
  margin-bottom: 40px;
  color: #222222;
  font-size: 0.9em;
}
.breadcrumb li {
  display: inline-block;
  padding: 0 5px;
}
.breadcrumb li:first-child {
  padding: 0 5px 0 0;
}
.breadcrumb li:last-child a {
  font-weight: bold;
}
.breadcrumb a {
  color: #015C21;
  font-weight: normal;
}

@media only screen and (max-width: 768px) {
  .outpatient .mb {
    margin-bottom: 100px;
  }
}
.outpatient .name {
  width: 70%;
  line-height: 1.5;
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  font-weight: bold;
  font-size: 0.9em;
}
.outpatient .name img {
  width: 100%;
}
@media screen and (min-width: 1110px) {
  .outpatient .name {
    width: 100%;
  }
}
.outpatient .site-main .link {
  color: #1F5AFE;
}
.outpatient .sectionHeading {
  margin-top: 0;
  color: #1F5AFE;
}
.outpatient .sectionHeading::before {
  background-image: url(../../img/top_backimage02.png);
}
@media only screen and (max-width: 768px) {
  .outpatient .sectionHeading::before {
    background-image: url(../../img/top_sp_backimage02.png);
  }
}
.outpatient .contentHeading::before {
  background-color: #1F5AFE;
}
.outpatient .contentHeading.fade_on::before {
  animation-name: bluepoint-a;
}
.outpatient .captitle {
  color: #1F5AFE;
}
.outpatient .colored {
  color: #1F5AFE;
  display: block;
}
.outpatient table th {
  background-color: #0075c2;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 0.2em;
}
.outpatient table td {
  text-align: center;
  padding: 0.2em;
}
.outpatient table .kamokuHeading {
  width: 20px;
}
.outpatient table .hourHeading {
  width: 14%;
}
.outpatient table .shinsatsuHeading {
  width: 66%;
}
.outpatient table .sideHeading {
  color: #0075c2;
  background-color: #f4f7ff;
  width: 32%;
}
@media only screen and (max-width: 768px) {
  .outpatient table .sideHeading {
    width: 40%;
  }
}
.outpatient table td, .outpatient table th, .outpatient table tr {
  border: 1px solid #ccc;
}
.outpatient table .hour {
  font-weight: bold;
}
@media screen and (min-width: 769px) {
  .outpatient table.w50 {
    width: 60%;
  }
}
.outpatient table.kamoku_schedule {
  width: 50%;
  margin-bottom: 0;
  position: relative;
  z-index: 333;
}
@media only screen and (max-width: 768px) {
  .outpatient table.kamoku_schedule {
    width: 100%;
  }
}
.outpatient table.kamoku_schedule .pageAreaOnly {
  font-weight: bold;
}
.outpatient .point {
  margin: 10px 0 100px;
}
@media screen and (min-width: 769px) {
  .outpatient .point .lists li {
    float: left;
    width: 70%;
  }
}
.outpatient#outpatient .page-heading {
  margin-bottom: 0;
}
.outpatient#outpatient.underpage-content {
  padding-bottom: 40px;
}
.outpatient#outpatient .lists {
  padding-left: 3%;
}
@media only screen and (max-width: 768px) {
  .outpatient#outpatient .section_content.access_way .content_right {
    margin: 15px 0;
  }
}
@media only screen and (max-width: 768px) {
  .outpatient#outpatient .section_content.access_way .content_right img {
    width: 80%;
    margin: 0;
  }
}
@media screen and (min-width: 769px) {
  .outpatient#outpatient .section_content.way_car {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 769px) {
  .outpatient#outpatient .section_content.public_traffic .content_left {
    width: 30%;
  }
}
@media screen and (min-width: 769px) {
  .outpatient#outpatient .section_content.public_traffic .content_right {
    float: left;
  }
}
.outpatient#outpatient .section_content .lists li::before {
  position: relative;
  top: -2px;
  left: -1px;
}
.outpatient#outpatient #section03 {
  margin-bottom: 100px;
}
@media only screen and (max-width: 768px) {
  .outpatient#outpatient #section03 .sectionHeading {
    margin-bottom: -40px;
  }
}
.outpatient#outpatient .contentHeading {
  margin-bottom: 5px;
}
.outpatient#outpatient .lists li {
  float: none;
}
.outpatient#outpatient table {
  margin-bottom: 50px;
}
.outpatient #internist .lists li {
  float: none;
}
@media screen and (min-width: 769px) {
  .outpatient #internist .content_left {
    width: 64%;
  }
}
@media only screen and (max-width: 768px) {
  .outpatient #internist .flexlist {
    display: block;
  }
  .outpatient #internist .flexlist .listcontent_right {
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }
  .outpatient #internist .flexlist .listcontent_left {
    width: 100%;
  }
}
.outpatient#orthopedics .flexlist {
  display: block;
  text-align: left;
}
.outpatient#orthopedics .flexlist figure {
  display: inline-block;
  vertical-align: text-top;
  margin: 5px;
}
.outpatient#orthopedics .flexlist figure .wp-caption-text {
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .outpatient#orthopedics .flexlist figure {
    width: 45%;
  }
}
@media screen and (min-width: 769px) {
  .outpatient#orthopedics .point2 .lists {
    width: 50%;
  }
}
.outpatient#orthopedics .drs {
  display: flex;
}
.outpatient#orthopedics .drs .name {
  margin-right: 20px;
  width: 48%;
}
@media screen and (min-width: 769px) {
  .outpatient#orthopedics .drs .name {
    width: 33%;
  }
}
@media only screen and (max-device-width: 560px) {
  .outpatient#orthopedics .drs .name {
    margin-right: 10px;
  }
}
.outpatient#dentistry .flex {
  justify-content: inherit;
}
@media screen and (min-width: 769px) {
  .outpatient#dentistry .flex .lists {
    width: 30%;
  }
}
.outpatient #orthopedics .flexlist, .outpatient #dentistry .flexlist, .outpatient #rehabilitation .flexlist {
  justify-content: space-between;
}
.outpatient#dentistry .point2 .flex, .outpatient#orthopedics .point2 .flex {
  justify-content: unset;
}
@media only screen and (max-width: 768px) {
  .outpatient#dentistry .point2 .flex, .outpatient#orthopedics .point2 .flex {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .outpatient#medical-checkup table .naiyou {
    width: 50%;
  }
  .outpatient#medical-checkup table .koumoku {
    width: 10%;
  }
}
@media screen and (min-width: 769px) {
  .outpatient#medical-checkup table.kenshin_table th:first-child {
    width: 20%;
  }
}
@media only screen and (max-width: 768px) {
  .outpatient#medical-checkup table.kenshin_table th:last-child {
    width: 24%;
  }
}
.outpatient#medical-checkup table.doc_table th:first-child {
  width: 20%;
}
.outpatient#medical-checkup table.doc_table td:last-child {
  width: 25%;
  text-align: left;
}
.outpatient#medical-checkup table.doc_table td.price {
  text-align: right;
}
.outpatient#medical-checkup table.option_table {
  position: relative;
  z-index: 10;
}
@media screen and (min-width: 769px) {
  .outpatient#medical-checkup table.option_table {
    width: 70%;
  }
}
@media only screen and (max-width: 768px) {
  .outpatient#medical-checkup table.option_table th {
    width: 14%;
  }
}
@media only screen and (max-width: 768px) {
  .outpatient#medical-checkup table.option_table td {
    width: 33%;
  }
  .outpatient#medical-checkup table.option_table td.option_subth {
    width: 16%;
  }
  .outpatient#medical-checkup table.option_table td.price {
    width: 22%;
  }
}
.outpatient#medical-checkup .t-left {
  text-align: left;
}
.outpatient#medical-checkup .t-right {
  text-align: right;
  font-weight: bold;
}
.outpatient#medical-checkup .price {
  text-align: right;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .outpatient#rehabilitation .flex.rihabiri {
    display: block;
    text-align: center;
  }
}
.outpatient#rehabilitation .flex.rihabiri .img {
  display: inline-block;
}
@media screen and (min-width: 769px) {
  .outpatient#rehabilitation .flex.rihabiri .img {
    width: 25%;
  }
}
@media only screen and (max-width: 768px) {
  .outpatient#rehabilitation .flex.rihabiri .img {
    width: 49%;
    padding: 9px 6px;
  }
}
.outpatient#rehabilitation .pointHeading {
  margin-bottom: 0;
}
.outpatient#rehabilitation .kamoku_schedule.rihabiri_schedule {
  margin-bottom: 40px;
}
@media screen and (min-width: 769px) {
  .outpatient#rehabilitation .kamoku_schedule.rihabiri_schedule {
    width: 60%;
  }
}
.outpatient#rehabilitation .lists {
  /* margin: 0 auto; */
  /* width: 80%; */
  margin-bottom: 20px;
}
@media screen and (min-width: 769px) {
  .outpatient#rehabilitation .lists {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
  }
}
.outpatient#rehabilitation .lists li {
  float: none;
}
@media screen and (min-width: 769px) {
  .outpatient#rehabilitation .lists li {
    width: 30%;
    padding-left: 10px;
  }
}

.hospitalization .sectionBtn {
  background-color: #FF7295 !important;
  box-shadow: 0px 4px 9px 0px rgba(255, 114, 149, 0.4) !important;
}
.hospitalization .point {
  background-color: #fec8d6;
}
.hospitalization .sectionHeading {
  color: #FF7295;
}
.hospitalization .sectionHeading::before {
  background-image: url(../../img/top_backimage03.png);
}
@media only screen and (max-width: 768px) {
  .hospitalization .sectionHeading::before {
    background-image: url(../../img/top_sp_backimage03.png);
  }
}
.hospitalization .colored {
  color: #FF7295;
  display: block;
}
.hospitalization .page-heading {
  box-shadow: 0px 0px 6px 3px #fec8d6;
}
.hospitalization .page-heading h1 {
  color: #FF7295;
}
.hospitalization .point2 {
  border: 1px solid #fec8d6;
  box-shadow: 4px 4px 6px 0px #fec8d6;
  margin-bottom: 0;
}
.hospitalization .point2 .pointHeading {
  color: #FF7295;
}
.hospitalization .caution {
  color: #FF7295;
  font-weight: bold;
}
.hospitalization .captitle {
  color: #FF7295;
}
.hospitalization h3::after {
  background-color: rgba(254, 200, 214, 0.3);
}
.hospitalization .contentHeading::before {
  background-color: #FF7295;
}
.hospitalization .contentHeading.fade_on::before {
  animation-name: pinkpoint-a;
}
.hospitalization .site-main .link {
  color: #FF7295;
}
.hospitalization#hospitalization .page-heading {
  margin-bottom: 0;
}
.hospitalization#hospitalization .headingImg {
  margin-bottom: 50px;
}
@media screen and (min-width: 769px) {
  .hospitalization#hospitalization .flex {
    width: 100%;
    justify-content: left;
  }
}
@media only screen and (max-width: 768px) {
  .hospitalization#hospitalization .flex {
    display: flex;
    justify-content: inherit;
    flex-wrap: wrap;
  }
}
@media only screen and (max-device-width: 560px) {
  .hospitalization#hospitalization .flex {
    display: block;
  }
}
.hospitalization#hospitalization .flex > img {
  width: 23%;
  margin: 8px;
}
@media only screen and (max-device-width: 560px) {
  .hospitalization#hospitalization .flex > img {
    width: 43%;
    margin-bottom: 3%;
    display: inline-block;
  }
}
.hospitalization#hospitalization #section01 {
  margin-bottom: 70px;
}
.hospitalization#hospitalization #section02 {
  margin-bottom: 100px;
}
.hospitalization#hospitalization .point2 .flex {
  margin: 15px 10px;
}
@media only screen and (max-width: 768px) {
  .hospitalization#hospitalization .point2 .flex {
    display: block;
  }
}
.hospitalization#hospitalization .point2 .lists {
  margin-right: 5%;
}
@media screen and (min-width: 1110px) {
  .hospitalization#hospitalization .point2 .lists {
    width: 30%;
  }
}
.hospitalization#hospitalization .lists {
  padding-left: 1%;
}
@media only screen and (max-width: 768px) {
  .hospitalization#hospitalization .lists {
    padding-left: 3%;
  }
}
.hospitalization#hospitalization .lists li::before {
  left: -5px;
}
@media only screen and (max-width: 768px) {
  .hospitalization#equipment #section01, .hospitalization#equipment #section02 {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 768px) {
  .hospitalization#equipment .content_left {
    margin-bottom: 40px;
  }
}

.guide .sectionBtn {
  background-color: #FF8E1E;
  box-shadow: 0px 0px 6px 3px #ffe5cc;
}
.guide .site-main .link {
  color: #FF8E1E;
}
.guide .point {
  background-color: #fdf1e4;
}
.guide .point .lists .colored {
  color: #d56a01;
}
.guide .point .lists .colored::before {
  background-color: #d56a01;
}
.guide .sectionHeading {
  color: #FF8E1E;
}
.guide .sectionHeading::before {
  background-image: url(../../img/top_backimage04.png);
}
@media only screen and (max-width: 768px) {
  .guide .sectionHeading::before {
    background-image: url(../../img/top_sp_backimage04.png);
  }
}
.guide .colored {
  color: #FF8E1E;
  display: block;
}
.guide .page-heading {
  box-shadow: 0px 0px 6px 3px #ffe5cc;
}
.guide .page-heading h1 {
  color: #FF8E1E;
}
.guide .point2 {
  border: 1px solid #ffe5cc;
  box-shadow: 4px 4px 6px 0px #ffe5cc;
  margin: 10px 0;
}
.guide .point2 .pointHeading {
  color: #FF8E1E;
}
.guide .captitle {
  color: #FF8E1E;
}
.guide h3::after {
  background-color: rgba(255, 229, 204, 0.3);
}
.guide .contentHeading::before {
  background-color: #FF8E1E;
}
.guide .contentHeading.fade_on::before {
  animation-name: orangepoint-a;
}
.guide .caution {
  color: #FF8E1E;
  font-weight: bold;
}
.guide .history table th {
  text-align: center;
  background-color: rgba(255, 229, 204, 0.3);
  font-weight: bold;
  color: #FF8E1E;
  padding: 1em;
}
.guide .history table td {
  display: flex;
  justify-content: space-between;
  border: none;
}
@media only screen and (max-width: 768px) {
  .guide .history table td {
    display: block;
  }
}
.guide#guide .rinen_heading {
  font-size: 36.8px;
}
@media only screen and (max-width: 768px) {
  .guide#guide .rinen_heading {
    font-size: 1.7em;
  }
}
.guide#guide .rinen_heading span {
  display: block;
  font-size: 27.2px;
}
@media only screen and (max-width: 768px) {
  .guide#guide .rinen_heading span {
    font-size: 20px;
  }
}
@media screen and (min-width: 1110px) {
  .guide#guide .section_content.rinen .content_left {
    width: 58%;
  }
}
.guide#guide .section_content.rinen .content_left .point {
  padding: 15px;
  margin: 0;
}
.guide#guide .section_content.rinen .content_left .point .lists {
  padding-left: 2%;
}
@media only screen and (max-width: 768px) {
  .guide#guide .section_content.rinen .content_left .point .lists {
    padding-left: 4%;
  }
}
@media screen and (min-width: 1110px) {
  .guide#guide .section_content.rijicho .content_right {
    width: 30%;
  }
  .guide#guide .section_content.rijicho .content_left {
    width: 70%;
  }
}
.guide#guide .point2 {
  background: url(../../img/jimucho_message.jpg) no-repeat right bottom;
  background-size: 360px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 768px) {
  .guide#guide .point2 {
    background-size: 260px;
  }
}
.guide#guide .point2 p {
  text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 5px rgba(255, 255, 255, 0.8);
}
@media only screen and (max-width: 768px) {
  .guide#guide .wp-video {
    margin: 0 auto;
  }
}
.guide#regional-activity h3 {
  margin-top: 0;
}
.guide#recruit .contentHeading {
  margin-bottom: 15px;
  font-weight: bold;
}
.guide#recruit .recruit_table {
  border: none;
  margin-bottom: 70px;
}
.guide#recruit .recruit_table td, .guide#recruit .recruit_table th {
  padding: 8px 15px;
  border: none;
}
@media only screen and (max-device-width: 560px) {
  .guide#recruit .recruit_table td, .guide#recruit .recruit_table th {
    padding: 8px 6px;
  }
}
.guide#recruit .recruit_table th {
  color: #FF8E1E;
  border-bottom: 2px solid #FF8E1E;
}
@media only screen and (max-width: 768px) {
  .guide#recruit .recruit_table th {
    width: 27%;
  }
}
.guide#recruit .recruit_table td {
  border-bottom: 2px solid #ccc;
}
.guide#recruit .recruit_table td > ul {
  overflow: hidden;
  padding-top: 5px;
}
.guide#recruit .recruit_table td > ul > li {
  float: left;
}
.guide#recruit .recruit_table td > ul > li > ul {
  float: right;
}
.guide#recruit .recruit_table tr {
  border: none;
}
.guide#recruit .section_content .content_right img {
  width: 100%;
}
.guide#recruit .voices {
  background-color: #fdf1e4;
  border-radius: 10px;
  margin-bottom: 40px;
  width: 100%;
  position: relative;
  padding: 20px;
}
@media only screen and (max-width: 768px) {
  .guide#recruit .voices {
    padding: 20px 20px 130px;
  }
}
.guide#recruit .voices figure {
  width: 100%;
}
.guide#recruit .voices .listcontent_left {
  width: 80%;
}
@media only screen and (max-width: 768px) {
  .guide#recruit .voices .listcontent_left {
    width: 100%;
  }
}
.guide#recruit .voices .listcontent_right {
  width: 90px;
  margin-top: -10px;
  position: absolute;
  bottom: 0;
  right: 30px;
}
@media screen and (min-width: 1110px) {
  .guide#recruit .voices .listcontent_right {
    width: 9%;
  }
}
.guide#recruit .voice {
  list-style-type: none;
}
.guide#recruit .voice li {
  padding-left: 22px;
  position: relative;
  margin-bottom: 1em;
}
.guide#recruit .voice li::before {
  content: " ";
  width: 15px;
  height: 15px;
  margin-left: -24px;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
  background-color: #333;
}
@media only screen and (max-width: 768px) {
  .guide#recruit .voice li::before {
    top: 0;
    width: 10px;
    height: 10px;
  }
}
.guide#recruit .voice li p {
  margin-bottom: 0;
}
.guide#recruit .point {
  margin-bottom: 30px;
  padding: 20px 20px 0;
}
.guide#recruit .point p {
  margin-bottom: 5px;
  padding-left: 25px;
}
@media only screen and (max-width: 768px) {
  .guide#recruit .point p {
    padding-left: 15px;
  }
}
.guide#recruit .point2 {
  padding: 20px 14px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .guide#recruit .point2 {
    padding: 20px 14px 28px;
  }
}
.guide#recruit .point2 .colored {
  font-size: 1.6em;
  font-family: "Yu Mincho Light", YuMincho, "Yu Mincho", "游明朝体", "ヒラギノ明朝 ProN W3", "Noto Serif JP", serif, sans-serif;
  font-weight: bold;
}
.guide#recruit .point2 .contactBox {
  margin-top: 30px;
}
.guide#recruit .point2 .contactBox .telHeading {
  position: relative;
  display: inline-block;
  color: #FF8E1E;
  font-size: 1.8em;
  font-weight: bold;
  vertical-align: middle;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .guide#recruit .point2 .contactBox .telHeading {
    margin: 0 0 10px 0;
  }
}
.guide#recruit .point2 .contactBox .telHeading::before {
  font-family: "Font Awesome 5 Free";
  content: "";
  font-size: 0.8em;
  margin-right: 10px;
  margin-top: 6px;
}
.guide#recruit .point2 .contactBox .contactBtn {
  padding: 5px 20px;
  position: relative;
  display: inline-block;
  background-color: #FF8E1E;
  color: #ffffff;
  border-radius: 50px;
}
@media screen and (min-width: 769px) {
  .guide#recruit .point2 .contactBox .contactBtn {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .guide#recruit .point2 .contactBox .contactBtn {
    width: 100%;
  }
}
.guide#recruit .point2 .contactBox .contactBtn::after {
  font-family: "Font Awesome 5 Free";
  content: "";
}
@media screen and (min-width: 769px) {
  .guide#recruit .point2 .contactBox .contactBtn::after {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .guide#recruit .point2 .contactBox .contactBtn::after {
    margin-left: 10px;
  }
}
.guide#recruit .subsectionHeading {
  font-size: 1em;
  margin: 0 0 30px;
}
.guide#privacy-policy .page-heading {
  margin-bottom: 0;
}
.guide#privacy-policy .mb {
  margin-bottom: 20px;
}
.guide#guide .point .pointHeading, .guide#regional-activity .point .pointHeading {
  margin-bottom: 0;
}
.guide#guide .point .ruby, .guide#regional-activity .point .ruby {
  font-size: 0.8em;
}
@media only screen and (max-width: 768px) {
  .guide#guide .point .ruby, .guide#regional-activity .point .ruby {
    font-size: 0.7em;
  }
}
.guide#guide .point .lists li, .guide#regional-activity .point .lists li {
  line-height: inherit;
}
@media only screen and (max-width: 768px) {
  .guide#guide .point, .guide#regional-activity .point {
    margin-bottom: 100px;
  }
}
.guide#guide .point2, .guide#regional-activity .point2 {
  position: relative;
  z-index: 11;
  background-color: #fff;
}
.guide#guide .flexlist, .guide#regional-activity .flexlist {
  flex-wrap: wrap;
}
@media only screen and (max-width: 768px) {
  .guide#guide .flexlist, .guide#regional-activity .flexlist {
    display: flex;
    justify-content: normal;
  }
}
.guide#guide .flexlist img, .guide#regional-activity .flexlist img {
  display: block;
  width: 18%;
  margin: 10px;
}
@media only screen and (max-width: 768px) {
  .guide#guide .flexlist img, .guide#regional-activity .flexlist img {
    width: 28%;
  }
}
@media only screen and (max-device-width: 560px) {
  .guide#guide .flexlist img, .guide#regional-activity .flexlist img {
    width: 42%;
  }
}
@media screen and (min-width: 769px) {
  .guide#guide .right, .guide#regional-activity .right {
    width: 33%;
    padding-left: 30px;
  }
}
@media screen and (min-width: 769px) {
  .guide#guide .left, .guide#regional-activity .left {
    width: 65%;
  }
}
@media screen and (min-width: 769px) {
  .guide#guide .pointFlex, .guide#regional-activity .pointFlex {
    flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 768px) {
  .guide#guide .pointFlex, .guide#regional-activity .pointFlex {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .guide#guide .pointFlex .right, .guide#regional-activity .pointFlex .right {
    width: 100%;
    padding: 0;
    margin: 10px 0;
  }
}
@media only screen and (max-width: 768px) {
  .guide#guide .pointFlex .left, .guide#regional-activity .pointFlex .left {
    width: 100%;
  }
}

/**********************
404ページ
********************/
/*--------------------------------------------------------------
404 error
--------------------------------------------------------------*/
/* 404 page */
.error404 {
  display: block;
  margin-top: 180px;
  padding: 0 20px;
}
.error404 .err_style {
  color: #015C21;
}