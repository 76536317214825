#slideL {
  z-index:3333;
  cursor: pointer;
  position: fixed;
  
  right: -483px;
  width: 530px;
  height: 380px;
  padding: 0 0 0 40px;
  margin-right: 483px;
  background-color:rgba(255,255,255,0.8);
  // &:hover{
  //   margin-right: 483px !important;
  // }
  // &:active{
  //   margin-right: 0 !important;
  // }
  // @include min1080{
    top: 40%;
  // }
  // @include tablet{
  //   height: 415px;
  //   top:340px;
  
  // }
  transition: 0.3s;
  transition-timing-function: ease;
  // &.on{
  //   margin-right: 483px;
  // }
  // &.off{
  //   margin-right: 0;
  // }
  .tate{
    color: #ffffff;
    position: absolute;
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    left: 0;
    background-color: $green2;
    display: block;
    font-family: $mincho;
    font-weight: bold;
    height: 100%;
    padding: 140px 9px;
    // @include tablet{
    // padding: 120px 9px;
      
    // }
    &Icon{
      padding-top: 14px;
      @include ieonly{
        &::before{
          position: absolute;
          left:9px;
        }
        &.fa-chevron-circle-right{
          &::before{
            content:"\f139" !important;
          }
        }
      }
      
    }
    @include ieonly{
      width: 9%;
      padding: 100px 12px;
    }
  }
}

.underpage{
  #slideL{
    margin-right: 0 ;
  }
}
#slideL .slide-inner {
  padding: 5px 20px 10px;
  height: 100%;
  cursor: default;
  p{
    margin: 0;
  }
  .denwa{
    color: $green;
    font-size: 1em;
    font-family: $mincho;
    font-weight: bold;
    margin-right: 10px;
  }
  .tel-txt{
    font-size: 1em;
    font-weight: bold;
    display: inline-block;
    margin-right:10px;
  }
  .tel-num{
    font-size: 1.4em;
    font-weight: bold;
    color: $green;
  }
  .sinryouHeading{
    font-family: $mincho;
    font-size: 1em;
    font-weight: bold;
    color: $green;
    line-height: 1;
    margin-bottom: 5px;
  }

  .sinryou{
    width: 100%;
    @include sp{
        width: 100%;
    }

    .shinsatsu,.shika{
        margin-left: 14px;
        margin: 0;
        border: 1px solid #ccc !important;
        
        .thead{
          background-color: rgba(#2ed569,0.2) !important;
            border-bottom: 1px solid #ccc;
            

            th{
                font-size: $px18;
                text-align: center;
                span{
                    font-size: 0.9em;
                    padding-left: 10px;
                    color:#3a8d57 !important;
                }
            }
            td{
                font-weight: bold;
                font-size: 0.8em;
            }
        }
        tbody{
          background-color: #fff;

        }
        th{
            width: 35%;
            text-align: left;
            border-right:1px solid #ccc;
            .hour{
                color: $blk !important;
                padding-left: 10px;
                font-size: 0.9em;
                text-align: left;
                display: inline-block;
                width: 100%;
            }
        }
        td{
          padding: 5px 13px;
          span{
            color:#000 !important;
          }
          &.first-td{
            margin-left: 10px;
          }
        }
        tr,td,th{
          padding: 2px;
          text-align: center;
          @include tablet{
            padding:2px 10px;
          }
        }
      .contentAreaOnly{
        display: none !important;
      }
      .jsAreaOnly{
        display: block !important;
        font-weight: bold;
      }
    }
    .caution{
        font-weight: bold;
        font-size: 0.8em;
        // margin-bottom:20px;
  
    }

    
  }
  .btnWrap{
    width: 100%;
    text-align: center;
    margin: 5px 0;
    @include tablet{
    margin: 10px 0;

    }
    .shinsatsuBtn{
      display: inline-block;
      width: 80%;
      padding: 8px;
      background-color: green;
      border-radius: 30px;
      color: #fff;
      text-align: center;
      font-size: 0.9em;
      cursor: pointer;
      .btnIcon{
        padding-left: 6px;
      }
    }
  }
}

.nav-icon{
  width: 50px;
  height: 50px;
  background-color: aqua;
  display: block;
}

