.section04{
    // margin-bottom: 46px;
    height: 202px;
    // @include sp{
    //     // margin-bottom: 80px;
    // }
    .map{
        width: 100%;
        height: 202px;
    }
    
}