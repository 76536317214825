.newspage{
    max-width: 1240px;
    padding:  0 20px;
    width: 100%;
    margin: 0 auto;
    @include min1080{
        margin-top: 250px;
    }

    @include pc{
        margin-top: 230px;
    }
    @include sp{
        margin-top: 30%;
        padding: 20px;
    }
    #primary{
        @include pc{
            float:left;
            width: 60%;
        }
        @include sp{
            margin-bottom: 50px;
        
        }
    }
    #secondary{
        @include pc{
            top: 250px;
            float: right;
            width: 30%;
        }
        // @include sp{
        //     width: 100%;
        //     position: fixed;
        //     top: 20%;
        //     z-index: 999999;
        //     right: 0;
        //     left: 0;
        //     margin: auto;
        //     background-color: #fff;
        // }
    }

    // @include sp{
    // #menu-newsmenu{
    //     width: 99%;
    //     margin: 0 auto;
    //     display: flex;
            
    //         justify-content: space-around;
    //         li{
    //             width: 33%;
    //             text-align: center;
    //             background-color: $green;
    //             a{
    //                 color: #ffffff;
    //                 box-shadow: none !important;
    //                 text-decoration: none;
    //             }
                
    //         }
    //     }
    // }

    // @include pc{
        #menu-newsmenu{
            li{
                a{
                    display: block;
                    font-size: 1em;
                    position: relative;
	                padding: 10px 20px 10px 10px;

                    // padding: 5px 20px 5px 10px;
                    z-index: 1;
                    color: #222;
                    width: 100%;
                    
                }
                &.catLink{
                    a{
                        &:hover{
                            background-color: $green;
                            color: #ffffff;
                            opacity: 1;
                            transition: 0.1s;
                            transition-timing-function: ease;
                            &:after{
                                color: #ffffff;
                            }
                        }
                        &:after{
                            content:" ";
                            width: 0;
                            height: 0;
                            display: inline-block;
                            position: absolute;
                            top: 35%;
                            right:10px;
                            margin-left: 6px;
                            border-style: solid;
                            border-width: 8px 0 8px 10px;
                            border-color: transparent transparent transparent $green;
                            // font-size: 0.7em;
                            // color: $green;
                            // position: absolute;
                            // right: 0;
                            // top:0;
                            // bottom: 0;
                            // margin: auto;
                            // display: flex;
                            // align-items: center;
                            // padding-right: 10px;
                        }
                    }
                    
                }
            }

            .catHeading{
                > a{
                    color: $green;
                    font-weight: bold;
                    cursor: default;
                    text-align: center;
                    font-size: 1.8em;
                    line-height: 12px;
                    margin-bottom: 10px;
                    &:hover{
                        opacity: 1;
                    }
                }
            }
    
            .current-menu-item{
                background-color: $green;
                a{
                    color: #ffffff;
                    position: relative;
                    &:after{
                        color: #fff;
                    }
                    
                }
            }
        }
    // }
    .entry-meta{
        margin-bottom: 15px;
    }
    .post-categories{
        display: inline-block;
        margin-left: 20px;
        @include sp{
            margin-left: 0;
        }
        li{
            display: inline-block;
            // a{
                display: inline-block;
                background-color: #ccc;
                padding: 2px 8px;
                margin: 0 3px;
                border-radius: 15px;
                color: #222;
                font-size: 0.8em;
                &.important{
                    background-color: #c7dc68;
                }
                &.medical{
                    background-color: rgba(#c7dc68,0.6);
                }
                &.other{
                    background-color: rgba(#c7dc68,0.3);
                }
            // }
        }
    }
    
    .articleHeading{
        .articleHeading{
            a{
                display: inline-block;
                background-color: $green;
                padding: 3px 10px;
                border-radius: 15px;
                color: #fff;
                font-size: 0.8em;
                margin-right: 5px;
            }
        }
        .entry-title{
            margin-right: 15px;
            margin-bottom: 0;
            color: $green ;
            font-size: 1.7em;
            font-family: $mincho;
            text-decoration: none;
            margin-left: -2px;


            overflow: hidden;
            text-overflow: ellipsis;
            // white-space: nowrap;
            // display: inline-block;
            width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
            width: 100%;
        }        
        a {
            color: #2f7445;
            text-decoration: none;
            margin-left: -2px;
            font-size: 1.2em;

        }
            
    }

    .moreTxt{
        color: $green;
        box-shadow: none;
    }

    img{
        margin-bottom: 20px;
        display: block;
    }

}

.no-results{
    padding: 40px;
}
.noarticle_title{
    color: $green;
    font-size: 1.5em;
    position: relative;
    z-index: 1;
    &::before{
        content: " ";
        width: 130px;
        height: 140px;
        display: block;
        background-image: url(../../img/top_backimage01.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left top;
        position: absolute;
        left: -52px;
        top: -34px;
        z-index: -1;

    }
    
}


// #articlePage{
    
// }

.archive .site-content, .single .site-content{
    border-bottom: 10px solid #adcbb8;
}