.section01{
    width: 100%;
    text-align: center;
    max-width: 1200px;
    padding: 0 100px;
    margin-bottom: 120px;
    // padding:57px 80px;
    @include sp{
        padding: 0;
        margin-bottom: 60px;
    }
    h2{
        margin-bottom: 32px;
    }
    .guide-area{
        width: 100%;
        // height: 160px;
        display: flex;
        justify-content: center;
        .guide-list{
            width: 32%;
            
            max-height: 180px;
            display: inline-block;
            border-left: 1px solid #aaa;
           @include pc{
            height: 100%;
           }
            &:last-child{
                border-right: 1px solid #aaa;
            }
            .guide-link{
                width: 100%;
                display: inline-block;
                height: 100%;
                color: #333333;
                @include pc{
                
                padding:20px 0 0;

                }
                &:hover{
                    transition: 0.3s;
                    transition-timing-function: ease;
                    opacity:1;
                }
                
                figure{
                    display: inline-block;
                    text-align: center;
                    padding: 20px;
                    height: 100px;
                    /* display: -webkit-box; */
                    /* display: flex; */
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    background-color: #fff;
                    width: 100px;
                    border-radius: 50%;
                    margin-bottom: 10px;
                    @include sp{
                        height: 80px;
                        width: 80px;
                        margin-top: 10px;
                    }

                    img{
                        // width: 16%;
                        width: 60%;
                        @include sp{
                            width: 62%;
                        }
                    }
                }
                span{
                    display: block;
                    margin-bottom: 20px;
                }
            }
            &:first-child{
                .guide-link{
                    &:hover{
                        background-color: #1F5AFE;
                        color: #ffffff;
                    }
                    
                }
            }

            &:nth-child(2){
                .guide-link{
                    &:hover{
                        background-color: #FF7295;
                        color: #ffffff;
                    }
                }
                figure{
                    padding: 26px 10px 10px;
                }
            }
            &:last-child{
                .guide-link{
                    &:hover{
                        background-color: #FF8E1E;
                        color: #ffffff;
                    }
                }
                figure{
                    padding: 26px 10px 10px;
                }
            }
        }
    }
}