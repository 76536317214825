h2{
    position: relative;
    // &.fade_on{
    //     .circle{
    //         opacity: 1;
    //     }
        
    // }
}

.section03{
    top: 22px;
}

// .sectionHeading{
//     &.fade_on{
//         .circle{
//             opacity: 1;
//         }
//     }
// }


@keyframes bluepoint-a {
    0% {
        box-shadow: 0 0 0 0 $outpatient;
    }
    50% {
        box-shadow: 0px 0px 4px 1px $outpatient;
    }
    100% {
        box-shadow: 0 0 0 0 $outpatient;

    }
}

@keyframes orangepoint-a {
    0% {
        box-shadow: 0 0 0 0 $guide;
    }
    50% {
        box-shadow: 0px 0px 4px 1px $guide;
    }
    100% {
        box-shadow: 0 0 0 0 $guide;

    }
}

    
@keyframes pinkpoint-a {
    0% {
        box-shadow: 0 0 0 0 $hospital;
    }
    50% {
        box-shadow: 0px 0px 4px 1px $hospital;
    }
    100% {
        box-shadow: 0 0 0 0 $hospital;

    }
}

.arrow{
    &::after{
        content: " ";
    width: 0;
    height: 0;
    /* padding-top: 11px; */
    /* margin-top: 1px; */
    /* vertical-align: top; */
    display: inline-block;
    position: absolute;
    top: 35%;
    margin-left: 6px;
    border-style: solid;
    border-width: 8px 0 8px 10px;
    border-color: transparent transparent transparent $green;
    }
    &:hover{
        &::after{
            border-color: transparent transparent transparent #fff;
        }
    }
}

.spBtnLists{
    position: absolute;
    top: 6%;
    bottom: 0;
    margin: auto;
    // background-color: yellow;
    right: 0;
    width: 107px;
    margin-right: 10px;
    width: 170px;
    display: block;
    @include max400{
        top: 0;
        width: 110px;
    }
    .menu-toggle{
        // display: block;
        display: block;
        position: absolute;
        width: 100%;
        padding-top: 14%;
        max-width: 80px;
        height: 80px;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        @include max400{
        max-width: 50px;
        height: 50px;
        }
    }
    
    
}

.hourLinkBtn{
    // display: block;
    display: block;
    position: absolute;
    width: 100%;
    padding-top: 14%;
    max-width: 80px;
    height: 80px;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    @include max400{
    max-width: 50px;
    height: 50px;
    }
    &::after{
        content: " ";
    width: 100%;
    height: 100%;
    padding-top: 14%;
    // max-width: 80px;
    background-image: url(../../img/top_sp_menu_time.png);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    top: 0;
        
    }
    &:hover{
        opacity: 1;
    }

}

.bold{
    font-weight: bold;
}
.mark{
    font-weight: bold;
    text-decoration: underline;
}

.colored{
    color: $green;
}

