/* =========================================================

 変数のみaaa

========================================================= */

// $s1: "only screen and (min-width:560px)";
// $s2: "only screen and (min-width:769px)";
// $s3: "only screen and (min-width:1071px)";
// $s4: "only screen and (min-width:1264px)";
// $s4: "only screen and (min-width:1281px)";
// $s5: "screen and (min-width:1281px)";
// $s5: "screen and (min-width:1071px)";
// $s5: "screen and (max-width:1740px) and (min-width:1072px)";
// $s5: "only screen and (min-width:1740px)";
// $sp1: "only screen and (max-width:559px)";
// $sp2: "only screen and (max-width:768px)";
// $sp3: "only screen and (max-width:1070px)";
// $sp3: "only screen and (max-width:959px)";

// $sp4: "only screen and (max-width:1263px)";
// $sp4: "only screen and (max-width:1280px)";
// $sp5: "only screen and (max-width:1739px)";
// $img_path: "/wp-content/themes/original/images/";



// $maxpc:"67.5em";
$maxpc:"1110px";
$minpc:"769px";
$sp:"768px";

$body: #fff;

$green : #015C21;
$green2 :#338D53;
$orange:#e79a46;
$gray:#aaa;
$txt:#636363;

$maingrn: #015c21;
// $gairai: #bdcdfe;
// $nyuin: #ffe5cc;
$Lcircle:#2ed569;
$Mcircle:#b4ff00;
$Scircle:#dbd91f;

$px18 :1.125em;

$site: #002665;
$site2: #ab9462;
$plan1: #85adb9;
$plan1-light: #e2edef;
$plan2: #508bb7;
$plan2-light: #cfe8fa;
$plan3: #003567;
$plan3-light: #e2e5fb;
$plan_ec1: #7a9c2b;
$plan_ec1-light: #f0fdd2;
$plan_ec2: #0a7c25;
$plan_ec2-light: #d2e4a8;

$footer: #002665;
$navi: #002665;
$link: #3273dc;
$color: #4a4a4a;
$color-light: #fff;
$color-title: #363636;
$strong: #363636;

$blk: #1a1a1a;
$gray: #9098a9;
$blue: #209bee;
$primary:#00d1b2;
$yellow: #fedd56;
$red: #fe385f;
$smoke: #e2e2e2;

$outpatient:#1F5AFE;
$guide:#FF8E1E;
$hospital:#FF7295;

$small: 0.875em;
$line-height: 1.85;
$anime-speed: 0.5;

$family: "游ゴシック Medium", YuGothic,'Yu Gothic',sans-serif,'ヒラギノ角ゴシック','Hiragino Sans';

$mincho: 'Yu Mincho Light',YuMincho,'Yu Mincho','游明朝体',"ヒラギノ明朝 ProN W3",'Noto Serif JP', serif,sans-serif;

@mixin maxwidth($width) {
  @media screen and (max-width: ($width)) {
    @content;
  }
}

@mixin media($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin media2($breakpoint1, $breakpoint2) {
  @media screen and (min-width: $breakpoint1) and (max-width:$breakpoint2) {
    @content;
  }
}

@mixin min1080 {
  @media screen and (min-width: ($maxpc)) {
    @content;
  }
}

@mixin max1080 {
  @media screen and (max-width: ($maxpc)) {
    @content;
  }
}

@mixin pc {
    @media screen and (min-width: ($minpc)) {
      @content;
    }
  }
  
@mixin sp {
  @media only screen and (max-width: ($sp)) {
    @content;
  }
}

// @mixin max400 {
//   @media only screen and (max-device-width: (560px)) {
//     @content;
//   }
// }
@mixin max400 {
  @media only screen and (max-device-width: (560px)) {
    @content;
  }
}


@mixin tablet {
  @media only screen and (min-width: $sp) and (max-width: $maxpc) {
    @content;
  }
}


@mixin showtablet {
  
  @media only screen and (min-width: 1080px) and (max-width:768px) {
    @content;
  }
}


.sponly{
  @include pc{
    display: none !important;
  }
}
.pconly{
  @include sp{
    display: none !important;
  }
}


.excepttablet{
  display: none;
  @include showtablet{
    display: block !important;
  }
}

.showtabletonly{
  @include showtablet{
    display: none !important;
  }
}

.showpconly{
  @include max1080{
    display: none;
  }
}

.exceptpc{
  @include min1080{
    display: none;
  }
}

.mincho{
  font-family: $mincho;
  font-weight: bold;
}

@mixin ffonly{
    @-moz-document url-prefix(){
        @content;
    }
}

@mixin ieonly{
    @media all and (-ms-high-contrast:none){
        @content;
    }
}


  


@mixin sfonly{
    @media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-color-index:0) {
	@content;
}

}

.opacity0{
  opacity : 0;
  transform: translateY(80px);
  transition: 1s;
}

// .anchor{
//   display: block;
//   padding-top: 200px;
//   margin-top: -200px;
// }

// $family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
