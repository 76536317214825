@import "value.scss";
@import "base.scss";
@import "header.scss";
@import "parts.scss";
/**********************
フロントページ
********************/
@import "hero.scss";
@import "section01.scss";
@import "section02.scss";
@import "section03.scss";
@import "section04.scss";
@import "section05.scss";
@import "footer.scss";
@import "social-menu.scss";
@import "swiper.scss";
@import "js.scss";
@import "sidebar.scss";
@import "pageheader.scss";
/**********************
おしらせ
********************/
@import "newspage.scss";

/**********************
下層固定ページ
********************/
@import "underpage.scss";
@import "breadcrumb.scss";
@import "outpatient.scss";
@import "hospitalization.scss";
@import "guide.scss";

/**********************
404ページ
********************/
@import "error404.scss";


// @import "page-outpacient.scss";

