

.hero{
    position: relative;
    @include media(1100px){
        margin-top:120px;
    }

    @include media(769px){
        margin-top: 170px;
        margin-bottom: 100px;
        // margin-top: 230px;
    }
    @include sp{
        margin-top: 16%;
    }
    @include max400{
        margin-top: 10%;
    }
    // @include ieonly{
    //     @include media(1100px){
    //         margin-top: 130px;
    //     }
    //     // @include media(790px){
    //     //     margin-top: 150px;
    //     // }
    //     @include media(769px){
    //         margin-top: 170px;
    //     }
    //     @include sp{
    //         margin-top: 21%;
    //     }
    // }

    h1{
        position: absolute;
        z-index: 111;
        
        // top: 50%;
        color: $green;
        text-shadow:0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6), 0 0 5px rgba(255, 255, 255, 0.6);
        @include media(935px){
            left: 3%;
            top: 55%;
        }
        @include media2(860px,935px){
            left: 1%;
            top: 50%;
        }
        @include media2(768px,860px){
            left: 1%;
            top: 44%;
        }

        @include sp{
            left: 3px;
            top: 190px;
            font-size: 1.2em;
            // padding: 0 3% 0 5%;
        }
        @include max400{
            // top: 33%;
            top: 170px;
            left: 4px;
        }
        // @include media2(361px,480px){
        //     top: 26% !important;
        // }
        .maintitle{
            font-weight: normal;
            font-size: 1.2em;
            // font-size: 1.5em;
            font-family: "M PLUS Rounded 1c";
            @include tablet{
                font-size: 1.1em;
                margin-bottom: 5px;
            }
            @include sp{
                font-size: 1.3em;
                margin-bottom: 0;
            }
            @include max400 {
                font-size: 1em !important;
            }
        }
        .subtitle{
            font-weight: normal;
            font-size: 1em;
            font-family: "M PLUS Rounded 1c";
            margin-left: 20px;
            display: inline-block;
            @include max400 {
                font-size: 0.8em !important;
            }
        }
    }
    .mainvisual{
        border-radius:40px;
        // max-height: 540px;
        // height: 100%;
        overflow: hidden;
        @include pc{
            // height: 520px;
        }
        @include sp{
            height: 295px;

            // height: 400px;
            border-radius: 0;
        }
        @include max400{
            height: 240px;
        }
        img{
           
            @include sp{
                // height: 100%;
                height: 110%;
                object-fit: cover;
                object-position: center 36px;
                // object-position: center 40px;
            }
            @include max400{
                height: 100%;
                // object-position: center 0;
            }
        }
    }
    .information{
        @include sp{
            padding:10px 20px;
        }
        
    }
    .info1{
        position: relative;
        z-index: 1111;
        margin-top: -28px;
        display: flex;
        border-radius: 30px;
        height: 50px;
        width: 57%;
        @include tablet{
            width: 92%;
        }
        @include sp{
            /* position: relative; */
            z-index: 1111;
            margin-top: 20px;
            /* display: -webkit-box; */
            display: block;
            border-radius: 10px;
            height: auto;
            width: 100%;
        }
        // a{
        //     display: block;
        //     height: 100%;
        //     width: 84%;
        //     @include sp{
        //         width: 100%;
        //     }
        // }
        .info_title{
            background-color: #2f7445;
            color: #ffffff;
            // width: 18%;
            width: 15%;
            height: 100%;
            border-radius: 30px 0 0 30px;
            display: -webkit-box;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            padding: 0 10px 0 16px;
            font-family: $mincho;
            font-weight: bold;

            @include sp{
                width: 100%;
                border-radius: 8px 8px 0 0;
                padding: 2px 0;
               margin-bottom: 0;
            }
        }
        dl{
            display: flex;
            align-items: center;
            background-color: #fff;
            // width: 100%;
            width: 85%;
            height: 100%;
            border: 1px solid $green;
         border-radius: 0 50px 50px 0;
            @include sp{
                display: block;
                width: 100%;
                padding:10px;
                border-radius: 0 0 10px 10px;
            }
            dt{
                color: $green;
                padding: 0 10px;
                
                @include pc{
                    width: 20%;
                // width: 22%;
                }
                // width: 80%;
                @include sp{
                    padding: 0;
                }
            }
            dd{
                @include pc{
                    width: 80%;
                }
                a{
                    color: $blk;
                    
                    width: 100%;
                    display: block;
                    @include pc{
                        width: 90%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    &:hover{
                        opacity: 1;
                        font-weight: bold;
                        text-decoration: underline;
                        color: $green;
                        transition: none !important;
                    }
                }
                
            }
        }
    }
    .reserveBtn{
        width: 100%;
        display: inline-block;
        text-align: center;
        background-color: $green2;
        padding: 6px;
        color: #fff;
        margin: 30px 0;
        font-family: $mincho;
        font-weight: bold;
        span{
            // color: #fff;
            display: inline-block;
            padding-right:10px ;
        }
    }
}