.underpage{
    padding-top: 9%;
    position: relative;
    @include sp{
        padding-top: 19%;
    }
    @include max400{
        padding-top: 17%;
    }
    
    .underpagewrap{
        // max-width: 1120px;
        // width: 100%;
        // padding:0 20px;
        // margin: 0 auto;

        max-width: 1200px;
        width: 100%;
        padding: 0 85px;
        margin: 0 auto;
        // @include tablet{
        //     width: 88%;
        //     padding: 0;
        // }
        @include sp{
            padding:0 10px
        }
    }
        .pageMainVisual{
            @include pc{
            // margin-bottom: 100px;
            }
            img{
                display: block;
                margin: auto;
                height: 245px;
                

                /* max-height: 300px; */
                width: 100%;
                object-fit: cover;
                @include sp{
                // object-fit: none;
                height: 200px;
                }
                
                @include max400{
                height: 120px;
                }
            }
        }
        .page-heading{
            position: relative;
            margin: auto;
            bottom: 70px;
            right: 0;
            left:0;
            width: 50%;
            margin: 0 auto;
            padding:20px;
            background-color: #fff;
            border-radius: 10px;
            text-align: center;
            box-shadow:0px 0px 6px 3px #a8c7f0;
            margin-bottom: -110px;

            
            @include sp{
                bottom: 100px;
                width: 90%;
                margin-bottom: -30px
            }
            @include tablet{
                width: 80%;
            }
            @include max400{
                bottom:40px;
            }
            h1{
                color:$outpatient;
                
            }
            span{
                display: block;
                margin: 10px 0;
                color: #4a4a4a;
                font-size: 0.54em;
                font-weight: normal;
                font-family: $family;
                line-height: 1.85;
                margin-block-start: 1em;
                margin-block-end: 1em;
            }
        }
        .point2{
            border: 1px solid #a8c7f0;
            box-shadow: 4px 4px 6px 0px #a8c7f0;
            border-radius: 20px;
            padding: 30px;
            margin: 30px 0 80px;
            .section_content{
                margin: 10px;
            }
            .pointHeading{
                font-size: 1.3em !important;
                color:$outpatient;
            }
        }
        p{
            // line-height: 1;
            z-index: 11;
            position: relative;
        }
    .caution{
        margin:0;
        font-size: 0.9em;
        &.zentai{
            margin-bottom: 50px;
            // font-size: 1.2em;
        }
    }
    li{
        line-height: 1.5;
    }
    .lists{
        list-style-type: none;
        list-style-position: inside;
        padding-left: 4%;
        // padding-left: 3%;
        overflow: hidden;
        width: 100%;
        li{
            &::before{
                position: relative;
            top: -2px;
            left: -8px;
           
            display: inline-block;
           
            width: 4px;
            height: 4px;
           
            content: '';
           
            border-radius: 100%;
            background:#333;
            }
            
        }

        &.disc2{
            li{
                &::before{
                width: 7px;
                height: 7px;
                background:#fff;
                border: 1px solid #000;
                }
                
            }
        }
    }

    

    h2{
        color: #4a4a4a;
    }

    .num{
        list-style-type:decimal;
        list-style-position: inside;
        &.flow{
            list-style-position: outside;
            margin-bottom: 50px;
            padding-left: 22px;
            font-size: 20px;
            color:$outpatient;
            font-weight: bold;
            li{
                margin-bottom: 15px;
                span{
                    font-size: 16px;
                    color: #000;
                    position: relative;
                    font-weight: normal;
                    display: inline-block;
                    vertical-align: text-top;
                    position: relative;
                }
                &:after{
                    content:" ";
                    display: block;
                    width: 0;
                    height: 0;
                    margin:10px 0 7px;
                    border-style: solid;
                    border-width: 13px 12px 0 12px;
                    border-color: rgba($outpatient,0.2) transparent transparent transparent;
                }
                &:last-child{
                    &::after{
                        display: none;
                    }
                }
                
            }
        }
    }
    
    figcaption{
        font-style: normal;
        font-size: 0.9em;
        // font-size: 1.1em;
        // font-family:$family ;
        // text-align: center;
    }
    .captitle{
        font-weight: bold;
    }
    .sectionlists{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 -10px 100px;
        position: relative;
        z-index: 888;
        
        @include ieonly{
            justify-content: baseline;
        }
        @include sp{
            text-align: center;
            margin: 0 auto;
            display: block;
            margin-bottom: 100px;
            a{
                width: 90%;
            }
        }
    }
    
    .sectionBtn{
        @include pc{
        width: 30%;
        }
        display: inline-block;
        border-radius: 50px;
        text-align: center;
        padding: 0.4em 2em;
        background-color: #0075c2;
        color: #ffffff !important;
        box-shadow:0px 4px 9px 0px rgba(#0075c2,0.5);
        transition: 0.3s;
        transition-timing-function: ease;
        @include sp{
            margin: 10px;
            padding: 0.6em;
        }
        &:hover{
            opacity: 1;
            transform: translateY(-2px);
        }
    }

    
    
    .lowersection{
        margin-bottom: 100px;
    }

    .sectionHeading{
        position: relative;
        font-family: $mincho;
        z-index: 10;
        font-size: 1.7em;
        color: $green;
        padding: 6%;
        

        @include pc{
            margin-top: 130px;
            margin-bottom: 20px;
            padding: 40px 20px 0 0;
            // padding: 40px 0 0 7%;
        }
        @include sp{
            margin: 70px 0 20px 0;
            // padding: 6% 33%;
            text-align: center;
            // margin-bottom: 60px;
        &::before{
            content: " ";
            width: 100%;
            height: 140px;
            display: block;
            background-image: url(../../img/top_sp_backimage01.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
        }
        }

        @include max400{
        margin-bottom: 30px;
        // font-size: 1.7em;
        padding: 6% 0;
        }
        &::before{
            content: " ";
            width: 157px;
            height: 216px;
            display: block;
            background-image: url(../../img/top_backimage01.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left top;
            position: absolute;
            top: -24px;
            left:-82px;
            font-size: 1.5em;
            z-index:-1;
            // @include tablet{
            //     top: -4px;
            //     left: -6%;
            // }
        }
        
       

    }

    .subsectionHeading{
        font-size: 0.8em;
        display: inline-block;
        margin-left: 20px;
    }
    

    .contentHeading{
        position: relative;
        font-weight: bold;
        // margin:20px 0;
        // font-size: 1em;
        margin: 25px 0 0 2px;
        font-size: 1.1em;
        // font-family: $mincho;
        &::before{
            content:" ";
            width: 15px;
            height: 15px;
            margin-right: 10px;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            top: 1px;
            // vertical-align: text-top;
        }

        &.fade_on{
            &::before{
                animation-name: point-a;
                animation-duration: 2s;
                animation-timing-function: linear;
                animation-iteration-count: 1;
            }
            
        }
    }

    

    h3{
        font-family: $mincho;
        // border-bottom: 4px solid rgba(#a8c7f0,0.3);
        // margin :20px 0;
        font-size: 1.5em;
        position: relative;
        z-index: 140;
        margin:40px 0 20px 0;
        @include sp{
            margin: 70px 0 20px 0;
        }
        &::after{
            content:"";
            transition:1s;
            transition-delay: 0.1s;
            transition-timing-function: ease;
            width: 100%;
            height: 4px;
            transform:scale(0,1);
            background-color: rgba(#a8c7f0,0.3);
            display: block;
            transform-origin: left;
        }
    }

    h3.fade_on{
        &::after{
            transform:scale(1,1);
        }
    }

    .site-main{
        a{
            font-weight: bold;
            // font-size: 0.9em;
            display: inline-block;
        }
        img{
            // width: 100%;
            object-fit: contain;
            position: relative;
        }
        // figure{
        //     width: 100% !important;
        // }
        .mb{
            margin-bottom: 100px;
            @include sp{
                margin-bottom: 80px;
            }
        }
    }
    
    .kamokuTitle{
        font-weight: bold;
        color: $outpatient;
        font-size: 1.3em;
        border-bottom: 3px solid rgba(#a8c7f0,0.3);
        display: inline-block;
        margin-bottom: 10px;
        font-family: $mincho;
        
    }

    .section02_content {
        @include sp{
            display: block;
        }
    }

    


    img{
        display: block;
    }

    .profile{
        text-align: center;
        figure{
            display: inline-block;
            margin-bottom: 0;
            img{
                width: 90%;
                margin: 0;
                        }
        }
        .wp-caption-text{
            text-align: left ;
            font-weight: bold;
            font-family: $mincho;
            line-height: 1.5;
            @include pc{
                margin-bottom: 0;
            }
        // font-size: 1em;
        }
    }

    //jimucho
    // .right{
    //     .profile{
    //         figure{
    //             width: 80%;
    //         }
    //     }
    // }

    .tleft{
        text-align: left !important;
    }

    .section_content{
        margin-bottom: 80px;
        position: relative;
        z-index: 269;
        display: block;
        overflow: hidden;
        @include min1080{
            width: 100%;
            
        }
        @include sp{
            margin-bottom: 40px;
        }
        .content_left{
            width: 100%;
            @include min1080{
                width: 58%;
                float: left;
                // width: 70%;
            }
        }
        .content_right{
            width: 100%;
            padding: 0 0 20px;
            @include min1080{
                width: 42%;
                float: right;
                // width: 30%;
                padding: 0 0 0 30px;
            }
            @include sp{
                margin: auto;
                
            }
            @include max400{
                padding: 0;
            }
            .flex{
                display: block;
                @include sp{
                    display: flex;
                }
            }
            
            img,.name{
                width: 100%;
                // width: 70%;
                margin: 0 auto;
                margin-bottom: 10px;
            }

            
            
        }

        // &._main{
        //     .content_left{
        //         padding-left: 40px;
        //     }
            
        // }

        &.block{
            display: block;

        }
    }

    .headingImg{
        width: 80%;
        margin: 0 auto;
        // margin-top: 50px;
        margin-bottom: -50px;
        @include sp{
            // margin-top: 0px;
            margin-bottom: 80px;
        }
        img{
            width: 100%;
            object-fit: contain;
        }
    }

    .link{
        font-size: 1em;
        text-decoration: underline;
        border-bottom: none !important;
        // border-bottom: 1px solid $outpatient;
        
    }
    
    .point{
        position: relative;
        z-index: 111;
        background-color: #f4f7ff;
        border-radius: 10px;
        padding: 20px;
        margin: 10px 0 ;
        .section_content{
            margin: 10px;
        }
    }
    .pointHeading{
        font-size: 1.3em;
        font-weight: bold;
        // color: $outpatient;
    }

    .underpage-content{
        border-bottom: 10px solid #adcbb8;
        @include pc{
            padding-bottom: 140px;
        }
    }

    
    .outpatient,.guide,#hospitalization{
        &.underpage-content{
            @include sp{
                padding-bottom: 100px ;
            }
        }
    }
    

    //画像二枚横並び
    .flex{
        display: flex;
        justify-content: space-around;
        width: 100%;
        > img{
            width: 45%;
        }
    }

    .flexlist{
        display: flex;
        // justify-content: space-between;
        @include max400{
            flex-wrap: wrap;
            // display: block;
        }
        .imgBox{
            width: 30%;
            text-align: center;
            @include max400{
                width: 100%;
            }
        }
        figure{
            width: 30%;
            @include max400{
                width: 48%;
            }
            img{
                width: 100%;
            }
        }
        .listcontent_left{
            width: 70%;
            @include max400{
                width: 100%;
            }
        }

        .listcontent_right{
            width: 25%;
            @include pc{
                margin-left: 5%;
            }
            @include max400{
                width: 90%;
                margin: auto;
                margin-bottom: 20px;
            }
            img{
                width: 100% ;
            }
        }


    }
    .pointFlex{
        display: flex;
        // align-items: center;
        width: 100%;
        // background-color: #fff;
        // justify-content: center;
        .left{
            width: 80%;
        }
        .right{
            width: 20%;
            padding-left: 10px;
            // padding: 0 10px 0 30px;
            // width: 25%;
        }
    }

    .mejs-container{
        border: 1px solid $green;
    }

    
    
}

// #outpatient,.hospitalization{
//     // #primary{
//     //     padding: 0 10%;
//     // }
//         .sectionHeading{
//             &::before{
//                 left:-82px;
//             }
//             @include pc{
//               padding: 40px 20px 0 0;
//               margin-top: 100px;
//             }
            
//         }
// }





#guide,#medical-checkup,#regional-activity,#recruit{
    .underpagewrap{
        @include pc{
            margin-top: -100px;
        }
    }
    
}









