.menu-social-container{
    .menu{
        display: flex;
    }
}

.footer-navigation{
    .js {
        .menu-toggle{
            display: none;
        }
    }
}

.copyright{
    margin-top: 30px;
    text-align: center;
}