.section03{
    position: relative;
    width: 100%;
    max-width: 1200px;
    padding: 0 40px 0 50px;
    margin-top: 70px;
    margin-bottom: 60px;
    @include sp{
        padding: 0 20px;
    }
    h2{
        position: relative;
        z-index: 10;
        // font-size: 1.8em;
        // color: $green;
        @include pc{
            padding-left: 20px;
        }
        &::before{
            content: " ";
            width: 130px;
            height: 140px;
            display: block;
            background-image: url(../../img/top_backimage01.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left top;
            position: absolute;
            left: -52px;
            top: -34px;
            z-index: -1;
        }
        @include sp{
            text-align: center;
            margin-bottom: 20px;
        &::before{
            content: " ";
            width: 100%;
            height: 140px;
            display: block;
            background-image: url(../../img/top_sp_backimage01.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
            position: absolute;
            top: -50px;
            left: 0;
            right: 0;
            margin: auto;

            @include ieonly{
                top:-60px;
            }
        }
        }

    }
    &_content{
        display: flex;
        width: 100%;
        justify-content: space-between;
        @include sp{
            display: block;
        }
        .credit{
            width: 55%;
            padding-top: 50px;
            @include sp{
                width: 100%;
            }
            .credit_inner{
                // display: inline-block;
                display: flex;
                align-items: center;
                width: 100%;
                @include sp{
                    display: block;
                }
            }
            .name{
                display: inline-block;
                width: 40%;
                margin-right: 20px;
                @include sp{
                    display: block;
                    width: 100%;
                    text-align: center;
                    span{
                        // font-weight: bold;
                        display: inline-block;
                        padding-left: 5px;
                        font-size: 1.4em;
                    }
                }
                div{
                   font-size: 1.25em; 
                   font-weight: bold;
                }
            }
            .address{
                display: inline-block;
                width: 50%;
                font-size: 0.9em;
                line-height: 1.4;
                @include sp{
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 1em;
                    font-weight: bold;
                }
                p{
                    
                    font-weight: bold;
                    .telHeading{
                        font-size: 22px;
                        color: $green;
                        @include sp{
                            font-weight: bold;
                            font-size: 2.1em;
                        }
                    }
                }
            }
            .links{
                width: 100%;
                text-align: left;
                @include sp{
                    margin-bottom: 40px;
                    text-align: center;
                    a{
                        display: inline-block !important;
                        width: 70% !important;
                        font-size: 1.1em;
                        padding: 10px !important;
                        // margin:0 auto;
                        text-align: center;
                        margin: 0 0 20px 0 !important;
                    }
                }
                @include max400{
                    a{
                        width: 90% !important;
                    }
                }
                .abouttime{
                    position: relative;
                    width: 35%;
                    display: inline-block;
                    padding: 3px;
                    text-align: center;
                    border-radius: 30px;
                    background-color: #ff8e1e;
                    color: #ffffff;
                    font-size: 0.9em;
                    margin-bottom: 10px;
                    @include tablet{
                        width: 45%;
                    }
                    &::after{
                        padding-left: 10px;
                        font-family: "Font Awesome 5 Free";
                        content: '\f017';
                        font-weight: 900;
                    }
                }
                .contactmail{
                    position: relative;
                    width: 30%;
                    display: inline-block;
                    padding: 3px;
                    text-align: center;
                    border-radius: 30px;
                    background-color: #ff8e1e;
                    color: #ffffff;
                    font-size: 0.9em;
                    margin-right: 20px;
                    @include tablet{
                        width: 40%;
                    }
                    &::after{
                        padding-left: 10px;
                        font-family: "Font Awesome 5 Free";
                        content:"\f0e0";
                        font-weight: 900;
                    }
                }


                .aboutaccess{
                    position: relative;
                    width: 24%;
                    display: inline-block;
                    // padding: 5px 20px;
                    padding: 3px;
                    text-align: center;
                    border-radius: 30px;
                    background-color: $green;
                    color: #ffffff;
                    font-size: 0.9em;
                    margin-bottom: 10px;
                    // font-weight: bold;
                    @include tablet{
                        width: 34%;
                    }
                    &::after{
                        padding-left: 10px;
                        font-family: "Font Awesome 5 Free";
                        content: '\f3c5';
                        font-weight: 900;
                    }
                }
            }
        }
        
    }
    
}




.sinryou{
            width: 50%;
            @include sp{
                width: 100%;
            }
            table{
                margin-left: 14px;
                margin: 0;
                border: none !important;
                .thead{
                    border-bottom: 1px solid #ccc;
                    th{
                        font-size: $px18;
                        span{
                            @include pc{
                                padding-left: 10px;
                            }
                        }
                    }
                    td{
                        font-weight: bold;
                    }
                }
                th{
                    width: 35%;
                    text-align: left;
                    .hour{
                        color: $blk !important;
                        
                        @include pc{
                            padding-left: 10px;
                        }
                    }
                }
                tr,td,th{
                    border: none;
                    padding:0;
                }
            }
            .caution{
                font-weight: bold;
                margin-bottom:20px;

            }
            .shinsatsu{
                .thead{
                    th{
                        color:#ff9600;
                    }
                }
                span{
                    color:#ff9600;
                }
                tr{
                    &:nth-child(2){
                        border-bottom: 1px solid #ccc;
                    }
                }
            }
            .shika{
                .thead{
                    th{
                        color:#3a8d57;
                    }
                }
                span{
                    color:#3a8d57;
                }
            }

            .jsAreaOnly{
                display: none !important;
            }
            .contentAreaOnly{
                display: block !important;
              }
        }