

.breadcrumb{
  font-weight: bold;
  // background-color: rgba($green,0.1);
  padding: 5px 0;
  margin-bottom: 40px;
  color: #222222;
  font-size: 0.9em;
  li{
    display: inline-block;
    padding: 0 5px;
    &:first-child{
      padding: 0 5px 0 0;  
    }
    &:last-child{
      a{
        font-weight: bold;
      }
      
    }
  }
  a{
    // font-size: 0.9em;
    color: $green ;
    font-weight: normal;
  }
  
}

// #archivePage{
//   .breadcrumb{
//     a{
//       &:last-child{
//           font-weight: bold;
//       }
//     }
//   }
  
// }