/****************tttttttttttteeeeeeeeeeeeeeeesssssssssssssttttttttttttt******************/

html {
    font-size: 16px;
    // font-size: 94%;
    // font-size: 1rem;
    box-sizing: border-box;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-rendering: optimizeLegibility;
   
  }
  
  body {
    width: 100%;
    word-break: break-word;
    font-weight: 400;
    line-height: $line-height;
    color: $color;
    margin: 0;
    padding: 0;
    word-break: break-all;
    font-family: $family;
    font-display: swap;
    background: $body;
    // overflow: hidden;
  
    /*IE用にマテリアルアイコン映す*/
    text-rendering: optimizeLegibility;
    font-kerning: normal;               
    font-variant-ligatures: common-ligatures contextual;
    font-feature-settings: "kern", "liga", "clig", "calt";
  }
  
  
  article,
  aside,
  figure,
  footer,
  header,
  hgroup,
  section {
    display: block;
  }
  
  code,
  pre {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    font-family: $family;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  html,
  body,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
  
  object {
    pointer-events: none;
  }
  
  img,
  audio,
  video {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    border: none;
  }
  
  iframe {
    border: 0;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid $smoke;
    // display: table-cell;
    //   vertical-align: top;
  }
  
  td,
  th {
    padding: 0.5rem;
    text-align: left;
    border: 1px solid $smoke;
    word-break: break-all;
    // @media #{$s3} {
    //   padding: 1rem;
    // }
  }
  
  table th {
    color: $strong;
  }
  
  h1,h2 {
    font-family: $mincho;
    color: $green;
    font-weight: normal;
  }

  h1{
    font-size: 1.8em;

  }

  h2{
    font-size: 1.7em;
  }
  
  h3 {
    font-size: 1.1rem;
    margin: 1.1rem 0;
    // @media #{$s1} {
    //   font-size: 1.2rem;
    //   margin: 1.2rem 0;
    // }
    // @media #{$s2} {
    //   font-size: 1.3rem;
    //   margin: 1.3rem 0;
    // }
    // @media #{$s3} {
    //   font-size: 1.4rem;
    //   margin: 1.4rem 0;
    // }
  }
  
  p {
    margin: 0 0 1rem;
  }
  
  ul {
    list-style: none;
  }
  
  ol {
    margin-left: 1em;
  }
  
  a {
    color: $link;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
    &[href^="tel:"] {
      @include pc{
        pointer-events: none;
      }
    }
  }
  .notlink{
    > a{
      pointer-events: none;
    }
    
  }
  
  b,
  strong {
    color: $strong;
    font-weight: bold;
  }
  
  small {
    font-size: $small;
  }
  
  hr {
    border: none;
    display: block;
  }
  
  pre {
    -webkit-overflow-scrolling: touch;
    background-color: whitesmoke;
    color: $color;
    font-size: $small;
    overflow-x: auto;
    padding: 1.25rem 1.5rem;
    white-space: pre;
    word-wrap: normal;
    code {
      background-color: transparent;
      color: currentColor;
      font-size: 1em;
      padding: 0;
    }
  }
  
  input[type="checkbox"],
  input[type="radio"] {
    vertical-align: baseline;
  }
  
  input[type="tel"],
  input[type="email"],
  input[type="text"] {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 1rem;
    appearance: none;
    border-bottom: 2px solid $color;
    &:focus {
      border-bottom: 2px solid $link;
    }
  }
  textarea {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 1rem;
    appearance: none;
    border: 2px solid $color;
    &:focus {
      border: 2px solid $link;
    }
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"],
  [role="button"] {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    appearance: none;
    &:hover {
      cursor: pointer;
    }
  }
  
  /*******************************
  kokomade************/
  