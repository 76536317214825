/*--------------------------------------------------------------
404 error
--------------------------------------------------------------*/
/* 404 page */
.error404{
	display: block;
  margin-top: 180px;
  padding: 0 20px;
  // @include sp{
  //   margin-top: 25%;

  // }
  // @include max400{
  //   margin-top: 35%;
  // }

  .err_style{
    color:#015C21;
    }
}