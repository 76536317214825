.menu-side-container{
    #menu-side{
        @include sp{
            display: block;
        }
        > .menu-item{
            @include sp{
                width: 100%;
                margin-bottom: 40px;
            }

            &:first-child{
                > a{
                    color:#1F5AFE;
                    &::before{
                        background-color:#bdcdfe;
                    }
                }
            }
            &:nth-child(2){
                > a{
                    color:#FF7295;
                    &::before{
                        background-color:#fec8d6;
                    }
                }
            }
            &:nth-child(3){
                > a{
                    color:#FF8E1E;
                    &::before{
                        background-color:#ffe5cc;
                    }
                }
            }
            
            > a{
                display: block;
                font-size: 1.2em;
                position: relative;
                padding: 10px;
                z-index: 1;
                text-shadow:0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff,0 0 3px #fff;
                &::before{
                    content:" ";
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    position: absolute;
                    top:0;
                    left: 0;
                    z-index: -1;
                }
                
            }

            
        }
        .shinsatsu-lists{
            margin-bottom: 20px;
        }

        .sub-menu{
            li{
                a{
                    display: block;
                    font-size: 1em;
                    position: relative;
                    padding: 5px 10px;
                    z-index: 1;
                    color: #222;
                    width: 100%;
                    &:hover{
                        background-color: #bdcdfe;
                        color: #ffffff;
                        opacity: 1;
                        transition: 0.1s;
                        transition-timing-function: ease;
                        &:after{
                            color: #ffffff;
                        }
                    }
                    &:after{
                        content:'▶';
                        font-size: 0.7em;
                        color: #bdcdfe;
                        position: absolute;
                        right: 0;
                        top:0;
                        bottom: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        padding-right: 10px;
                    }
                }
            }

            .current_page_item{
                background-color: #bdcdfe;
                a{
                    color: #ffffff;
                    position: relative;
                    &:after{
                        color: #fff;
                    }
                }
            }
        }

    }
}

// #social-menu li ul li a{
//     font-size: 1em !important;
// }