.header-area{
    .header-above{
        @include pc{
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 1240px;
            margin: 0 auto;
            padding: 0 20px;
            margin-bottom: 10px;
            align-items: baseline;
        }
        padding: 12px 12px 0;
        // padding: 20px 20px 0;
        @include max400{
            padding: 0 12px;
        }
        .header-left{
            width: 40%;
            position: relative;
            z-index: 99999;
            @include sp{
                top:4px;
                width: 60%;
                // width: 60%;
            }
        }
        
        .tel{
            font-size: 2.5em;
            font-weight: bold;
            color: #2f7445;
            position: relative;
            margin-bottom: 0;
            margin-right: 34px;
            @include tablet{
                font-size: 1.5em;
            }
            @include sp{
                margin-right: 0;
                font-size: 2.2em;
            }
            @include max400{
                font-size: 1.6em;
            }
            &::before{
                font-family: "Font Awesome 5 Free";
                content: "\f879";
              //   color:$green;
            //   color: #333;
              font-size: 0.8em;
              margin-right: 10px;
            }
        }
    }
    .header-bottom{
        width: 100%;
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
        // &.pconly{
        //     @include maxwidth(845px){
        //         button,nav{
        //             display: none !important;
        //         }
        //     }
            
        // }
    }
    .right-above{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 50%;
        @include tablet{
            width: 60%;
        }
        @include sp{
            height: 0;
        }
        // &.pconly{
        //     @include maxwidth(845px){
        //         // button,nav{
        //             display: none !important;
        //         // }
        //     }
            
        // }
    }
    .header-subtitle{
        width: 100%;
        max-width: 1240px;
        margin: 0 auto;
        padding: 0 20px;
        font-size: 0.9em;
        // @include maxwidth(845px){
        //     // button,nav{
        //         display: none;
        //     // }
        // }
        @include sp{
            display: none;
        }
    }

    #top-menu{
        text-align: center;
        // @include sp{
        //     padding: 5%;
        // }
    }

    .logo{
        width: 50%;
        @include sp{
            width: 85%;
            margin-bottom: 0;
        }
    }
    .dropdown-toggle{
        display: none;
    }
    // .main-navigation li:last-child a{
	// 	border-right:1px solid #ccc;

	// }
    .main-navigation{
        display: block;
        max-width: 100% !important;
        @include sp{
            height: 0;
        }
        li{
            
            @include pc{
                width: calc(98% / 4);
                
            }
           @include tablet{
            width: calc(96% / 4) ;
           }
            @include sp{
                position: relative;
                width: 100%;
                text-align: left;
                padding: 2%;
                
            }
            // &.parentCatLink{
            //     @include pc{
            //         // margin-bottom: 7px;
                    
            //         display: inline-block;
            //         // height: 57px;
            //         &:last-child{
            //             // padding-top: 10px;
            //             a{
            //             border-right: 1px solid #ccc;
            //             // border-right: 0;
            //                 // padding: 10px 0;
            //             }
            //         }
            //     }
            //     @include sp{
            //         &::after,&::before{
            //             content: '';
            //             display: block;
            //             width: 15px;
            //             height: 3px;
            //             border-radius: 5px;
            //             background: #474747;
            //             position: absolute;
            //             right: 20px;
            //             top: 30px;
            //             transform: translateY(-50%);
            //         }
            //         &::after {
            //             // background: red;
            //             /* 横棒を縦にするために90℃回転させる */
            //             transform: translateY(-50%) rotate(90deg);
            //             /* プラスからマイナスへの切り替えをゆっくり行いアニメーションさせる */
            //             transition: 0.5s;
            //             // &.focus{
            //             //     transform: rotate(0);
            //             //     transition: 0.5s;
            //             // }
            //           }
            //         &.focus{
            //             &::after {
            //                 transform: rotate(0);
            //                 transition: 0.5s;
            //                 top: 28.9px;
            //               }
            //         }
    
                    
            //     }
            //     >a{
            //         display: block;
            //         // margin-bottom: 7px;
            //         // height: 100%;
            //         cursor:default;
            //         &:hover{
            //             opacity: 1;
            //         }
            //         @include pc{
            //         border-left: 1px solid #ccc;

            //         }
            //     }
            //     &.oshirase{
            //         cursor:pointer;
            //         &:hover{
            //             opacity: 0.7;
            //         }
            //     }
                

            // }
            > a{
                width: 100%;
                padding: 6px;
                border-left:0;
                position: relative;
                @include pc{
                    padding: 10px 10px 0px 54px;
                    margin-bottom: 5px;
                    // margin-bottom: 8px;
                }
                @include tablet{
                    padding: 10px !important;
                }
            }



            
        }
        
    }
    nav{
        width: 100% !important;
    }

    @include pc{
        .sub-menu{
            li{
                a{
                    height: 100%;
                    padding: 20px 5px 20px !important;
                }
            }
        }
    }
    

    

}

// @include min1080{
    
// }

.header-area .main-navigation > .menu-top-container > #top-menu >li:first-child >a::before{
    // width: 100% ;
    padding-top: 13% ;
    top:-1px ;
    left: -34% ;

}


.header-area .main-navigation > .menu-top-container > #top-menu >li:nth-of-type(2) >a::before{
    // width: 100%;
    padding-top: 16%;
    top: -5px;
    left:-22% ;

}

.header-area .main-navigation > .menu-top-container > #top-menu >li:nth-of-type(3) >a::before{
    // width: 100%;
    padding-top: 11%;
    left: -34% ;
    top: 7px;

}

//下層のアイコンの位置

#header-underpage .main-navigation > .menu-top-container > #top-menu >li:first-child >a::before{
    width: 34px;
    height: 34px;
    padding-top: 13% ;
    top:-1px ;
    left: 19%;
}


#header-underpage .main-navigation > .menu-top-container > #top-menu >li:nth-of-type(2) >a::before{
    // width: 100%;
    padding-top: 26%;
    top: -19px;
    // top: -5px;
    left: 28%;

}

#header-underpage .main-navigation > .menu-top-container > #top-menu >li:nth-of-type(3) >a::before{
    // width: 100%;
    width: 34px;
    height: 34px;
    padding-top: 11%;
    left: 12% ;
    top: 7px;

}
#header-underpage .menu-top-container > ul{
    display: flex;
}

// #header-underpage .main-navigation > .menu-top-container > #top-menu >li:first-child >a::before {
    
// }



.header-area .main-navigation > .menu-top-container > #top-menu >li:last-child >a{
    padding: 10px 0 0;

}

@include min1080{
    .main-navigation > .menu-top-container > #top-menu >li >a::before{
        content:" ";
        background-repeat: no-repeat;
        background-position:center center ;
        
        background-size: contain;
        display: block;
        position: absolute;
    }
}
    