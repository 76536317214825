.outpatient{
  .mb{
      @include sp{
          margin-bottom: 100px;
      }
  }

  .name{
        width: 70%;
        line-height: 1.5;
        font-family: $mincho;
        font-weight: bold;
        font-size: 0.9em;
        img{
            width: 100%;
        }
        @include min1080{
            width: 100%;
        }
    }
  
  
  

  .site-main{
      .link{
          color: $outpatient;
          // border-bottom: 1px solid $outpatient;
          
      }
      
  }
  .sectionHeading{
        margin-top: 0;
      color: $outpatient;
      &::before{
          background-image: url(../../img/top_backimage02.png);
      }
      @include sp{
          &::before{
              background-image: url(../../img/top_sp_backimage02.png);
          }
      }
      
  }
  .contentHeading{
      &::before{
          background-color: $outpatient;
      }
      &.fade_on{
          &::before{
              animation-name: bluepoint-a;
          }
          
      }
  }
  .captitle{
      color: $outpatient;
  }
  .colored{
      color: $outpatient;
      display: block;
  }
  table{
      th{
        background-color: #0075c2;
        //   background-color: rgba(#a8c7f0,0.4);
          color: #fff;
          font-weight: bold;
          text-align: center;
          padding: 0.2em;
      }
      td{
          text-align: center;
          padding: 0.2em;
      }
      .kamokuHeading{
          width: 20px;
      }
      .hourHeading{
          width: 14%;
      }
      .shinsatsuHeading{
          width: 66%;
      }
      .sideHeading{
        color: #0075c2;
        background-color: #f4f7ff;
        // background-color: rgba(#a8c7f0,0.2);
        width: 32%;
        @include sp{
            width: 40%;
        }
      }

      td,th,tr{
          border: 1px solid #ccc;
      }
      .hour{
          font-weight: bold;
      }
      &.w50{
          @include pc{
              width: 60%;

          }
      }
    //   &.outpatient_hour{
    //       .sideHeading{
    //           width: 35%;
    //           @include sp{
    //               width: 40%;
    //           }
    //       }
    //   }
      &.kamoku_schedule{
          width: 50%;
          // margin: 0 auto;
          margin-bottom: 0;
          position: relative;
          z-index: 333;
          @include sp{
              width: 100%;
          }
          .pageAreaOnly{
            font-weight: bold;
          }
          
      }
  }

//   @include tablet{
//     .section_content{
//     //   display: flex;
//       .content_left{
//         width: 70%;
//       }
//       .content_right{
//         width: 30%;
//         padding: 10px;
//       }
//     }
//   }

  .point{
    margin: 10px 0 100px;
    .lists{
        li{
            @include pc{
                float: left;
                width: 70%;
            }
        }
    }
  }

  
  

  &#outpatient{
      .page-heading{
        margin-bottom: 0;
      }
    &.underpage-content {
        padding-bottom: 40px;
    }
    .lists{
        padding-left: 3%;
    }
    .section_content{
        &.access_way{
            .content_right{
                @include sp{
                    margin: 15px 0;
                }
                img{
                    @include sp{
                        width: 80%;
                        margin: 0;
                    }
                }
                
            }
        }
        
       &.way_car{
        @include pc{
            margin-bottom: 30px;
        }
    }
        // .content_right{
        // img{
        //     @include sp{
        //         margin: 15px 0 10px !important;
        //      }   
        // }
        // }
       &.public_traffic{
        .content_left{
            @include pc{
                width: 30%;
            }
            
        }
        .content_right{
            @include pc{
                float:left;
            }
            
            // img{
            // @include sp{
            //     width: 70%;
            //     /* margin: 0 auto; */
            //     margin: 15px 0 10px !important;
            // }
            // }
        }
    }

        .lists{
            li{
                &::before{
                    position: relative;
                    top: -2px;
                    left: -1px;
                }
            }
        } 

       
      
    }
    #section03{
        margin-bottom: 100px;
        .sectionHeading{
            @include sp{
                margin-bottom: -40px;
            }
            
        }
    }
    .contentHeading {
        margin-bottom: 5px;
    }
    .lists{
        li{
            float: none;
        }
    }

    table{
        margin-bottom: 50px;
    }
    
}


#internist{
    .lists{
        li{
            float: none;
        }
    }
    .content_left{
        @include pc{
            width: 64%;
        }
    }

    .flexlist{
        // display: flex;
        // justify-content: space-between;
        @include sp{
            display: block;
            // display: block;
            .listcontent_right{
                width: 80%;
                margin: auto;
                margin-bottom: 20px;
            }
            .listcontent_left{
                width: 100%;
            }
        }
    }
}



&#orthopedics{
    .flexlist{
        display: block;
        text-align: left;
        figure{
            display: inline-block;
            vertical-align: text-top;
            margin: 5px;
            .wp-caption-text{
                text-align: left;
            }
            @include sp{
                width: 45%;
            }
        }
    }

    .point2{
        .lists{
            @include pc{
             width: 50%;
            }
           
        }
    }

    .drs{
        display: flex;
        .name{
            margin-right: 20px;
            width: 48%;
            @include pc{
                width: 33%;
            }
            @include max400{
                margin-right: 10px;
            }
        }
    }
    
}

&#dentistry{
.flex{
    justify-content: inherit;
    .lists{
        @include pc{
            width: 30%;
        }
    }
}
}

#orthopedics,#dentistry,#rehabilitation{
    .flexlist{
        justify-content: space-between;
    }
}
//liが５以上
&#dentistry,&#orthopedics{
    .point2{
        .flex{
            justify-content: unset;
            @include sp{
                display: block;
            }
            
            
        }
    }
    
    
}

// &#internist{
//     .sectionHeading{
//         margin-top: 0;
//         @include max400{
//             margin-top: 20px;
//         }
//     }
// }

&#medical-checkup{
    // .sectionHeading{
    //     @include max400{
    //         margin-top: 20px;
    //     }
    // }
    table{
        @include sp{
            .naiyou{
                width: 50%;
            }
            .koumoku{
                width: 10%;
            }
            
        }

        &.kenshin_table{
            th{
                &:first-child{
                    @include pc{
                        width: 20%;
                    }
                }
                &:last-child{
                    @include sp{
                        width: 24%;
                    }
                }
            }
        }
        // .medical_table{
        //     margin-bottom: 80px;
        //     th{
        //         &:first-child{
    
        //             width:20%;
        //         }
        //         &:last-child{
        //             @include sp{
        //                 width: 26%;
        //             }
        //         }
        //     }
            
        // }
        &.doc_table{
            th{
                &:first-child{
    
                    width:20%;
                }
                // &:last-child{
                //     @include sp{
                //         width: 26%;
                //     }
                // }
            }
            td{
                &:last-child{
                    width: 25%;
                    text-align: left;
                }
                &.price{
                    text-align: right;
                }
            }
        }
        &.option_table{
            position: relative;
            z-index: 10;
            @include pc{
                width: 70%;
            }
            th{
                @include sp{
                    width: 14%;
                }
            }
            td{
                @include sp{
                    width: 33%;
                &.option_subth {
                    width: 16%;
                }
                &.price {
                    width: 22%;
                }
                }
                
            }
            
        }
    }
    
    // th{
    //     &:last-child{
    //         width: 15%;
    //     }
        
    // }
    // td{
    //     &:last-child{
    //         width: 15%;
    //     }
    // }
    .t-left{
        text-align: left;
    }
    .t-right{
        text-align: right;
        font-weight: bold;
    }
    .price{
        text-align: right;
         font-weight: bold;
    }
    
}


    
&#rehabilitation{
    .flex{
        &.rihabiri{
            @include sp{
                display: block;
                text-align: center;
            }
            
            .img{
                display: inline-block;
                @include pc{
                    width: 25%;
                }
                @include sp{
                    width: 49%;
                    padding: 9px 6px;
                }
            }
            // .img1,.img2{
            //     @include sp{
            //         width: 49%;
            //     } 
            // }
            // .img3{
            //     @include sp{
            //         width: 99%;
            //         margin-top: 6px;
            //     }
            // }
        }
    }
    .pointHeading{
        margin-bottom:0;
    }
    .kamoku_schedule {
        &.rihabiri_schedule{
            @include pc{
                width: 60%;
            }
            margin-bottom: 40px;
        }
        
    }

    .lists{
        @include pc{
            display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        }
        
        /* margin: 0 auto; */
        /* width: 80%; */
        margin-bottom: 20px;
        li{
            @include pc{
                width: 30%;
                padding-left: 10px;
            }
            float: none;
        }
    }
}
}